import React, { useMemo, useState } from 'react'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { Table, Button, Col, Row, Card, Collapse } from 'react-bootstrap'
import { FaChevronRight } from 'react-icons/fa'
import Loader from '../../../../../components/Loader'
import RangeCalendar from '../../../../../components/RangeCalendar'
import { MultiselectFormGroup, SingleselectFormGroup } from '../../../../../components/FormGroups'
import { useGetCashFlowQuery } from '../../../../../redux/slices/reports'
import {
	useLoadCashFlowTypesListQuery,
	useLoadOrganizationsSelectQuery
} from '../../../../../redux/slices/financeDictionaries'
import {
	AccountMonthlyRow,
	CashFlowReportResult,
	CashFlowRow,
	HierarchicalOverallTotals
} from '../../../../../types/finance/reports'
import CashFlowExcelButton from './Buttons/CashFlowReportExcelButton'
import TableError from '../../../../../components/TableError'
import {
	displayValue,
	groupMonthsByYear,
	flattenHierarchicalData,
	getNonEmptyMonthIndicesForHierarchical,
	getNonEmptyMonthIndicesForRows
} from '../../../../../utils/reportsExcelParser'

const NATIVE_CURRENCY_NAME = 'UAH'
const FIXED_COLUMN_STYLE = { width: '500px', minWidth: '500px', maxWidth: '500px' }

interface Month {
	month: string
	year: string
}

interface CashFlowRowItemProps {
	row: CashFlowRow
	level: number
	months: Month[]
	monthIndices: number[]
	showNative: boolean
}

function CashFlowRowItem({
							 row,
							 level,
							 months,
							 monthIndices,
							 showNative,
						 }: CashFlowRowItemProps) {
	const [isOpen, setIsOpen] = useState(false)
	const hasChildren = row.children && row.children.length > 0

	const firstCellStyle = {
		...FIXED_COLUMN_STYLE,
		paddingLeft: `${20 * level}px`,
	}

	const bgColor =
		row.isArchived
			? '#d3d3d3'
			: level === 0
				? 'rgba(157,203,255, 0.5)'
				: 'inherit'

	const renderMonthCells = (originalIdx: number) => {
		if (showNative) {
			return (
				<>
					<td key={`mgm-${originalIdx}`} className="text-right text-nowrap">
						{displayValue(row.monthly[originalIdx])}
					</td>
					<td key={`native-${originalIdx}`} className="text-right text-nowrap">
						{displayValue(row.nativeMonthly ? row.nativeMonthly[originalIdx] : 0, NATIVE_CURRENCY_NAME)}
					</td>
				</>
			)
		} else {
			return (
				<td key={originalIdx} className="text-right text-nowrap">
					{displayValue(row.monthly[originalIdx])}
				</td>
			)
		}
	}

	const renderTotalCell = () => {
		if (showNative) {
			return (
				<>
					<td className="text-right text-nowrap">
						{displayValue(row.total)}
					</td>
					<td className="text-right text-nowrap">
						{displayValue(row.nativeTotal ?? 0, NATIVE_CURRENCY_NAME)}
					</td>
				</>
			)
		} else {
			return (
				<td className="text-right text-nowrap">
					{displayValue(row.total)}
				</td>
			)
		}
	}

	return (
		<>
			<tr
				style={{ cursor: hasChildren ? 'pointer' : 'default', backgroundColor: bgColor }}
				onClick={() => {
					if (hasChildren) setIsOpen(!isOpen)
				}}
			>
				<td className="text-break text-wrap align-middle" style={firstCellStyle}>
					{hasChildren && (
						<FaChevronRight
							size={12}
							className="me-1 mx-1"
							style={{
								transition: 'transform 0.2s',
								transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
							}}
						/>
					)}
					{row.name}
				</td>
				{monthIndices.map(idx => renderMonthCells(idx))}
				{renderTotalCell()}
			</tr>
			{isOpen &&
				row.children &&
				row.children.map(child => (
					<CashFlowRowItem
						key={child.id}
						row={child}
						level={level + 1}
						months={months}
						monthIndices={monthIndices}
						showNative={showNative}
					/>
				))}
		</>
	)
}

interface HierarchicalTableProps {
	data: CashFlowRow[]
	overallTotals: HierarchicalOverallTotals
	months: Month[]
	showNative: boolean
}

function HierarchicalTable({ data, overallTotals, months, showNative }: HierarchicalTableProps) {
	const { t } = useTranslation()

	const flatData = flattenHierarchicalData(data)
	const nonEmptyIndices = getNonEmptyMonthIndicesForHierarchical(flatData, overallTotals, months.length)
	const filteredMonths = months.filter((_, idx) => nonEmptyIndices.includes(idx))
	const yearGroups = groupMonthsByYear(filteredMonths)
	const headerColSpan = showNative ? 2 : 1

	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<thead>
			<tr>
				<th rowSpan={2} className="align-middle text-break text-wrap" style={FIXED_COLUMN_STYLE}>
					{t('cashFlowType')}
				</th>
				{yearGroups.map((group, idx) => (
					<th key={idx} colSpan={group.count * headerColSpan} className="text-center">
						{group.year}
					</th>
				))}
				<th rowSpan={2} colSpan={headerColSpan} className="align-middle text-center text-break text-wrap">
					{t('total')}
				</th>
			</tr>
			<tr>
				{filteredMonths.map((m, idx) => (
					<React.Fragment key={idx}>
						<th className="text-center" colSpan={headerColSpan}>
							{m.month}
						</th>
					</React.Fragment>
				))}
			</tr>
			</thead>
			<tbody>
			{data.map(row => (
				<CashFlowRowItem
					key={row.id}
					row={row}
					level={0}
					months={filteredMonths}
					monthIndices={nonEmptyIndices}
					showNative={showNative}
				/>
			))}
			<tr className="fw-bold bg-warning">
				<td>{t('total')}</td>
				{nonEmptyIndices.map(idx => (
					<React.Fragment key={idx}>
						<td className="text-right text-nowrap">
							{displayValue(overallTotals.monthly[idx])}
						</td>
						{showNative && (
							<td className="text-right text-nowrap">
								{displayValue(
									overallTotals.nativeMonthly ? overallTotals.nativeMonthly[idx] : 0,
									NATIVE_CURRENCY_NAME
								)}
							</td>
						)}
					</React.Fragment>
				))}
				<td className="text-right text-nowrap">
					{displayValue(overallTotals.total)}
				</td>
				{showNative && (
					<td className="text-right text-nowrap">
						{displayValue(overallTotals.nativeTotal, NATIVE_CURRENCY_NAME)}
					</td>
				)}
			</tr>
			<tr className="fw-bold bg-info">
				<td>{t('starting_cash')}</td>
				{nonEmptyIndices.map(idx => (
					<React.Fragment key={idx}>
						<td className="text-right text-nowrap">
							{displayValue(overallTotals.starting[idx])}
						</td>
						{showNative && (
							<td className="text-right text-nowrap">
								{displayValue(
									overallTotals.nativeStarting ? overallTotals.nativeStarting[idx] : 0,
									NATIVE_CURRENCY_NAME
								)}
							</td>
						)}
					</React.Fragment>
				))}
				<td className="text-right text-nowrap"></td>
				{showNative && <td className="text-right text-nowrap"></td>}
			</tr>
			<tr className="fw-bold bg-success">
				<td>{t('closing_cash')}</td>
				{nonEmptyIndices.map(idx => (
					<React.Fragment key={idx}>
						<td className="text-right text-nowrap">
							{displayValue(overallTotals.closing[idx])}
						</td>
						{showNative && (
							<td className="text-right text-nowrap">
								{displayValue(
									overallTotals.nativeClosing ? overallTotals.nativeClosing[idx] : 0,
									NATIVE_CURRENCY_NAME
								)}
							</td>
						)}
					</React.Fragment>
				))}
				<td className="text-right text-nowrap"></td>
				{showNative && <td className="text-right text-nowrap"></td>}
			</tr>
			</tbody>
		</Table>
	)
}

interface AccountMonthlyTableProps {
	data: AccountMonthlyRow[]
	months: Month[]
}

function AccountMonthlyTable({ data, months }: AccountMonthlyTableProps) {
	const { t } = useTranslation()

	const nonEmptyIndices = getNonEmptyMonthIndicesForRows(data, months.length)
	const filteredMonths = months.filter((_, idx) => nonEmptyIndices.includes(idx))
	const yearGroups = groupMonthsByYear(filteredMonths)
	const totals = nonEmptyIndices.map(idx =>
		data.reduce((sum, row) => sum + row.monthly[idx], 0)
	)

	return (
		<Table bordered hover responsive className="table-striped table-sm mx-3">
			<thead>
			<tr>
				<th rowSpan={2} className="align-middle text-break text-wrap" style={FIXED_COLUMN_STYLE}>
					{t('account')}
				</th>
				{yearGroups.map((group, idx) => (
					<th key={idx} colSpan={group.count} className="text-center">
						{group.year}
					</th>
				))}
			</tr>
			<tr>
				{filteredMonths.map((m, idx) => (
					<th key={idx} className="text-center">
						{m.month}
					</th>
				))}
			</tr>
			</thead>
			<tbody>
			{data.map(row => (
				<tr key={row.accountId}>
					<td style={FIXED_COLUMN_STYLE}>{row.accountName}</td>
					{nonEmptyIndices.map(idx => (
						<td key={idx} className="text-right text-nowrap">
							{displayValue(row.monthly[idx])}
						</td>
					))}
				</tr>
			))}
			<tr className="fw-bold bg-warning">
				<td style={FIXED_COLUMN_STYLE}>{t('total')}</td>
				{nonEmptyIndices.map((idx, i) => (
					<td key={idx} className="text-right text-nowrap">
						{displayValue(totals[i])}
					</td>
				))}
			</tr>
			</tbody>
		</Table>
	)
}

interface CollapsibleAccountMonthlyTableProps {
	data: AccountMonthlyRow[]
	months: Month[]
}

function CollapsibleAccountMonthlyTable({ data, months }: CollapsibleAccountMonthlyTableProps) {
	const { t } = useTranslation()
	const [isOpen, setIsOpen] = useState(false)

	const toggleFolding = () => setIsOpen(!isOpen)

	return (
		<Card className="mt-3">
			<Card.Header className="d-flex align-items-center justify-content-between">
				<Card.Title as="h4" className="mb-0">
					<Button
						variant="link"
						onClick={toggleFolding}
						aria-controls="collapseAccountTable"
						aria-expanded={isOpen}
						className="p-0 me-2 align-content-center justify-content-center"
					>
						<FaChevronRight
							size={12}
							style={{ transform: isOpen ? 'rotate(90deg)' : 'none' }}
							className="mr-2"
						/>
						{t('CashFlowReport_accountsTable')}
					</Button>
				</Card.Title>
			</Card.Header>
			<Collapse in={isOpen}>
				<div id="collapseAccountTable">
					<AccountMonthlyTable data={data} months={months} />
				</div>
			</Collapse>
		</Card>
	)
}

export default function CashFlowReport() {
	const { t, i18n } = useTranslation()

	const { data: cfTypes = [] } = useLoadCashFlowTypesListQuery()
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery()

	const [documentDateFrom, setDocumentDateFrom] = useState<Date>(moment().startOf('year').toDate())
	const [documentDateTo, setDocumentDateTo] = useState<Date>(moment().endOf('year').toDate())

	const [selectedCashFlowTypes, setSelectedCashFlowTypes] = useState<string[]>([])
	const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([])

	const [viewMode, setViewMode] = useState<'incomeExpense' | 'cashFlowType'>('incomeExpense')
	const [showNative, setShowNative] = useState(true)
	const [useArchived, setUseArchived] = useState(false)

	const ranges = [
		{ value: 'q1', label: 'for_first_quarter', index: 0 },
		{ value: 'q2', label: 'for_second_quarter', index: 1 },
		{ value: 'q3', label: 'for_third_quarter', index: 2 },
		{ value: 'q4', label: 'for_fourth_quarter', index: 3 },
		{ value: 'hy', label: 'for_half_year', index: 4 },
		{ value: 'y',  label: 'for_one_year', index: 5 },
		{ value: 'at', label: 'for_all_time', index: 6 }
	]

	const handleQuickSelect = (option: { value: string; label: string }) => {
		let newFrom: Date
		let newTo: Date

		switch (option.value) {
			case 'q1': {
				newFrom = moment().startOf('year').toDate()
				newTo = moment().startOf('year').add(2, 'months').endOf('month').toDate()
				break
			}
			case 'q2': {
				newFrom = moment().startOf('year').add(3, 'months').startOf('month').toDate()
				newTo = moment().startOf('year').add(5, 'months').endOf('month').toDate()
				break
			}
			case 'q3': {
				newFrom = moment().startOf('year').add(6, 'months').startOf('month').toDate()
				newTo = moment().startOf('year').add(8, 'months').endOf('month').toDate()
				break
			}
			case 'q4': {
				newFrom = moment().startOf('year').add(9, 'months').startOf('month').toDate()
				newTo = moment().startOf('year').add(11, 'months').endOf('month').toDate()
				break
			}
			case 'hy': {
				newFrom = moment().startOf('year').add(6, 'months').toDate()
				newTo = moment().endOf('year').toDate()
				break
			}
			case 'y': {
				newFrom = moment().startOf('year').toDate()
				newTo = moment().endOf('year').toDate()
				break
			}
			case 'at': {
				newFrom = moment('01.01.2022', 'DD.MM.YYYY').toDate()
				newTo = moment().endOf('year').toDate()
				break
			}
			default: {
				newFrom = moment().startOf('year').toDate()
				newTo = moment().endOf('year').toDate()
				break
			}
		}

		setDocumentDateFrom(newFrom)
		setDocumentDateTo(newTo)
	}

	const validFrom = documentDateFrom || moment().startOf('year').toDate()
	const validTo = documentDateTo || moment().endOf('year').toDate()

	const filters = useMemo(() => ({
		startDate: validFrom.toISOString(),
		endDate: validTo.toISOString(),
		organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
		cashFlowTypes: selectedCashFlowTypes.length ? selectedCashFlowTypes : undefined,
		language: i18n.language as 'en' | 'uk' | undefined,
		mode: viewMode,
		useArchived,
	}), [validFrom, validTo, selectedOrganizations, selectedCashFlowTypes, i18n.language, useArchived, viewMode])

	const { data: reportData, isLoading, isFetching, isError } = useGetCashFlowQuery(filters)

	const report: CashFlowReportResult =
		reportData || {
			hierarchical: [],
			accountMonthly: [],
			overallTotals: {
				starting: Array(12).fill(0),
				monthly: Array(12).fill(0),
				total: 0,
				closing: Array(12).fill(0)
			}
		}

	const startMoment = moment(documentDateFrom).locale(i18n.language)
	const endMoment = moment(documentDateTo).locale(i18n.language)
	const monthCount = endMoment.diff(startMoment, 'months') + 1
	const months: Month[] = []
	for (let i = 0; i < monthCount; i++) {
		const m = startMoment.clone().add(i, 'months')
		months.push({ month: m.format('MMM'), year: m.format('YYYY') })
	}

	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<Col className='mb-3 mb-md-0'>
					<RangeCalendar
						startDate={documentDateFrom}
						endDate={documentDateTo}
						withSelect
						withFullWidth
						onChange={([start, end]) => {
							setDocumentDateFrom(start!)
							setDocumentDateTo(end!)
						}}
					/>
				</Col>
				<Col>
					<SingleselectFormGroup
						placeholder=""
						options={ranges.map(r => ({ ...r, label: t(r.label) }))}
						defaultValue={{ ...ranges[5], label: t(ranges[5].label) }}
						onChange={val => val && handleQuickSelect(val)}
						isClearable={false}
						error={false}
					/>
				</Col>

				<Col md={12} lg={12} xxl={6} className='text-right'>
					<CashFlowExcelButton
						data={reportData}
						dateFrom={documentDateFrom}
						dateTo={documentDateTo}
						showNative={showNative}
					/>
				</Col>
			</Row>

			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org: any, idx: number) => ({
							value: org._id,
							label: org.name,
							index: idx,
						}))}
						onChange={selected => setSelectedOrganizations(selected.map(s => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('cashFlowType')}
						options={cfTypes.map((cf: any, idx: number) => ({
							value: cf._id,
							label: cf.ukName || cf.enName,
							index: idx,
						}))}
						onChange={selected => setSelectedCashFlowTypes(selected.map(s => s.value))}
					/>
				</Col>
			</Row>

			<Row xs={1} sm={1} md={1} lg={1} xxl={3}>
				<Col className="d-flex flex-wrap align-items-center justify-content-start px-3 py-2">
					<span>{`${t('filter')}:`}</span>
					<Button
						variant={useArchived ? 'outline-primary' : 'primary'}
						onClick={() => setUseArchived(false)}
						className="my-1 mx-2 shadow-none"
					>
						{t('no_use_archived')}
					</Button>
					<Button
						variant={useArchived ? 'primary' : 'outline-primary'}
						onClick={() => setUseArchived(true)}
						className="my-1 shadow-none"
					>
						{t('use_archived')}
					</Button>
				</Col>

				<Col className="d-flex flex-wrap align-items-center justify-content-start justify-content-xxl-center px-3 py-2">
					<span>{`${t('group_by')}:`}</span>
					<Button
						variant={viewMode === 'incomeExpense' ? 'primary' : 'outline-primary'}
						onClick={() => setViewMode('incomeExpense')}
						className="my-1 mx-2 shadow-none "
					>
						{t('by_direction')}
					</Button>
					<Button
						variant={viewMode === 'cashFlowType' ? 'primary' : 'outline-primary'}
						onClick={() => setViewMode('cashFlowType')}
						className="my-1 shadow-none"
					>
						{t('by_cashflow_type')}
					</Button>
				</Col>

				<Col className="d-flex flex-wrap align-items-center justify-content-start justify-content-xxl-end px-3 py-2">
					<span>{`${t('display_with')}:`}</span>
					<Button
						variant={showNative ? 'primary' : 'outline-primary'}
						onClick={() => setShowNative(true)}
						className="my-1 mx-2 shadow-none"
					>
						{t('full_report')}
					</Button>
					<Button
						variant={!showNative ? 'primary' : 'outline-primary'}
						onClick={() => setShowNative(false)}
						className="my-1 shadow-none"
					>
						{t('short_report')}
					</Button>
				</Col>
			</Row>
			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : report.hierarchical.length > 0 ? (
				<>
					<HierarchicalTable
						data={report.hierarchical}
						overallTotals={report.overallTotals}
						months={months}
						showNative={showNative}
					/>
					<CollapsibleAccountMonthlyTable data={report.accountMonthly} months={months} />
				</>
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	)
}
