import { useTranslation } from 'react-i18next'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import React from 'react'
import {
	downloadNetSettlementExcel
} from '../../../../../../utils/reportsExcelParser'
import { FaFileExcel } from 'react-icons/fa'

interface NetSettlementExcelButtonProps {
	data?: any;
	showNative?: boolean;
	dateFrom: Date,
	dateTo: Date
}

export default function NetSettlementExcelButton({ data, showNative = true, dateTo, dateFrom }: NetSettlementExcelButtonProps) {
	const { t } = useTranslation()

	if (!data || data.length <= 0) return null

	const load = async () => {
		return await downloadNetSettlementExcel(data, showNative, dateFrom, dateTo)
	};

	const renderTooltip = (props) => (
		<Tooltip id="tooltip-left" className={'custom-tooltip left'} {...props}>
			{t('DownloadNetSettlementsReport_text')}
		</Tooltip>
	)

	return (
		<OverlayTrigger placement="left" overlay={renderTooltip}>
			<Button
				id='NetSettlementsReportDowloadButton'
				variant="outline-success"
				onClick={load}
				className='rounded-lg shadow-sm ml-2 mb-0'
			>
				<FaFileExcel />
			</Button>
		</OverlayTrigger>
	)
}