import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons/lib'
import { RolesEnum } from 'types/roles'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/types'
import RoleWrapper from '../RoleWrapper'

interface ModalViewProps {
	className?: string

	title: any
	titleExtendet?: any
	children?: any
	size?: 'sm' | 'lg' | 'xl' | 'xxl'

	noLeftMargin?: boolean // 1
	noButton?: boolean // 14 (all finance)
	show?: boolean

	requiredRoles?: RolesEnum[]

	buttonText: string
	tooltipText?: string
	tooltipPlacement?: Placement // wll be usefull
	id?: string
	numberButtonSize?: number // 0
	buttonSize?: 'sm' | 'lg' // 2 lg 3 sm
	buttonIcon?: IconType
	buttonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	buttonOutline?: boolean // 2
	buttonSmall?: boolean // 11
	buttonAsText?: boolean // 2

	acceptButtonText?: string
	acceptButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	onAccept?: any
	deleteModal?: boolean // 8
	clearFunction?: any

	rejectButtonText?: string
	onReject?: any // 2
	onRejectButtonClick?: any // 1
	rejectButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string // 3

	disabled?: boolean // 1
	strictClose?: boolean
}

const ModalView = (props: ModalViewProps) => {
	const [isOpen, setIsOpen] = useState(props.show || false)
	const { t, i18n } = useTranslation()

	useEffect(() => {
		if (props.show) {
			setIsOpen(props.show);
		}
	}, [props.show]);

	const toggle = () => {
		setIsOpen(!isOpen);
	}

	const onAccept = async () => {
		if (!props.onAccept) {
			toggle()
			return
		}

		const success = await props.onAccept()

		if (props.deleteModal) {
			return
		}

		if (success) {
			toggle()
		}
	}

	const onReject = () => {
		if (!props.onReject) {
			toggle()
			return
		}

		props.onReject()

		if (props.deleteModal) {
			return
		}

		toggle()
	}

	const tooltipId = 'Tooltip' + (props.tooltipText ?? '') + (props.id ?? '0')

	const linkItem =
		props.buttonAsText && !props.noButton ? (
			<Button
				variant='ghost'
				className={`m-0 p-0 text-${props.buttonColor ?? 'primary'} ${props.className}`}
				id={tooltipId}
				onClick={() => toggle()}
			>
				{props.buttonIcon && (
					<props.buttonIcon
						className={props.buttonSmall ? '' : 'mr-2'}
						size={props.numberButtonSize}
					/>
				)}
				{!props.buttonSmall && t(props.buttonText)}
			</Button>
		) : (
			<></>
		)

	const buttonItem =
		!props.buttonAsText && !props.noButton ? (
			<Button
				id={tooltipId}
				variant={(props.buttonOutline ? 'outline-' : '') + (props.buttonColor ?? 'primary')}
				disabled={props.disabled}
				style={props.buttonSmall ? { width: '28px', height: '28px', borderRadius: '4px' } : {}}
				className={`justify-content-center align-items-center d-flex shadow-sm ${props.buttonSmall ? 'p-0' : ''} mb-0 ${props.noLeftMargin ? '' : 'ml-2'} ${props.className}`}
				onClick={toggle}
				size={props.buttonSize}
			>
				{props.buttonIcon && (
					<props.buttonIcon
						className={props.buttonSmall ? '' : 'mr-2'}
						size={props.numberButtonSize}
					/>
				)}
				{!props.buttonSmall && (
					<span>
						{i18n.exists(props.buttonText) ? t(props.buttonText) : props.buttonText}
					</span>
				)}
			</Button>
		) : (
			<></>
		)

	const renderWithTooltip = (item) => (
		props.tooltipText ? (
			<OverlayTrigger
				overlay={
					<Tooltip
						id={tooltipId}
						className={`custom-tooltip ${props.tooltipPlacement ?? 'left'}`}
					>
						{t(props.tooltipText)}
					</Tooltip>
				}
				placement={props.tooltipPlacement ?? 'left'}
			>
				{item}
			</OverlayTrigger>
		) : (
			item
		)
	);

	const modalView = (
		<>
			{renderWithTooltip(buttonItem)}
			{renderWithTooltip(linkItem)}

			<Modal
				show={isOpen}
				onHide={onReject}
				centered
				keyboard={!props.strictClose}
				backdrop={props.strictClose ? 'static' : true}
				size={props.size !== 'xxl' ? props.size : 'xl'}
				dialogClassName={props.size === 'xxl' ? 'extra-large-modal' : ''}
				onExited={props.clearFunction}
			>
				<Modal.Header closeButton closeLabel="Close">
					<div className="d-flex align-items-start w-100 pe-4">
						<div>{i18n.exists(props.title) ? t(props.title) : props.title}</div>
						{props.titleExtendet}
					</div>
				</Modal.Header>

				{props.children && <Modal.Body className="m-1">{props.children}</Modal.Body>}

				{(props.rejectButtonText || props.acceptButtonText) && (
					<Modal.Footer>
						{props.rejectButtonText && (
							<Button
								variant={`${props.rejectButtonColor ?? 'secondary'}`}
								onClick={() => {
									if (props.onRejectButtonClick) {
										props.onRejectButtonClick()
									}
									onReject()
								}}
							>
								{t(props.rejectButtonText)}
							</Button>
						)}{' '}
						{props.acceptButtonText && (
							<Button
								variant={`${props.acceptButtonColor ?? 'primary'}`}
								onClick={onAccept}
							>
								{t(props.acceptButtonText)}
							</Button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	)

	if (props.requiredRoles)
		return <RoleWrapper requared_role={props.requiredRoles}>{modalView}</RoleWrapper>

	return <>{modalView}</>
}

export default ModalView
