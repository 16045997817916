import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup } from 'components/FormGroups';
import { FaEdit } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';

import { RolesEnum } from '../../../../../types/roles';
import { InvoiceClass } from '../../../../../types/finance/invoice'
import UpdateModal from '../../../../../components/Modals/UpdateModal'
import { useUpdateInvoiceClassMutation } from '../../../../../redux/slices/invoices'

interface UpdateInvoiceClassModalProps {
	invoiceClass: InvoiceClass;
}

const UpdateInvoiceClassModal = ({ invoiceClass }: UpdateInvoiceClassModalProps) => {
	const { t } = useTranslation();

	const [enName, setEnName] = useState(invoiceClass.enName || '');
	const [ukName, setUkName] = useState(invoiceClass.ukName || '');

	const [enNameError, setEnNameError] = useState(false);
	const [ukNameError, setUkNameError] = useState(false);

	const [updateInvoiceClass] = useUpdateInvoiceClassMutation();

	const handleUpdateInvoiceType = async () => {
		let isValid = true;

		// Set error flags based on validation
		setUkNameError(!ukName);
		setEnNameError(!enName)

		if (!ukName || !enName) {
			isValid = false;
		}

		if (!isValid) return false;

		const changes = {
			enName,
			ukName
		};

		try {
			await updateInvoiceClass({id: invoiceClass._id, changes: changes});
			clearForm();
			return true;
		} catch (error) {
			console.error("Error updating invoice class:", error);
			return false;
		}
	};

	const clearForm = () => {
		setUkName('');
		setEnName('');
	};

	return (
		<UpdateModal
			id={invoiceClass._id}
			title={'UpdateInvoiceClass_title'}
			icon={FaEdit}
			onAccept={handleUpdateInvoiceType}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('ukName')}
						value={ukName}
						onChange={(e) => {
							setUkName(e.target.value);
							setUkNameError(false);
						}}
						error={ukNameError}
					/>
				</Col>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('enName')}
						value={enName}
						onChange={(e) => {
							setEnName(e.target.value);
							setEnNameError(false);
						}}
						error={enNameError}
					/>
				</Col>
			</Row>
		</UpdateModal>
	);
};

export default UpdateInvoiceClassModal;
