import ModalView from './ModalView'
import { RolesEnum } from '../../types/roles'
import { IconType } from 'react-icons/lib'

interface DangerModalProps {
	id: string;
	title: string
	onAccept: () => void
	icon: IconType
	requiredRoles?: RolesEnum[]
	tooltipText? : string
}

export default function DangerModal({ id, title, onAccept, icon, requiredRoles, tooltipText }: DangerModalProps) {
	return (
		<ModalView
			id={`DangerModal_${title}_${id}`}
			title={title}
			tooltipText={tooltipText}
			buttonIcon={icon}
			buttonText=""
			numberButtonSize={16}
			buttonColor="danger"
			tooltipPlacement='top'
			buttonOutline
			className={'rounded-lg'}
			buttonSmall
			onAccept={onAccept}
			acceptButtonText={title}
			acceptButtonColor="danger"
			requiredRoles={requiredRoles}
		/>
	)
}