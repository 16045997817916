import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder';
import {
	CreateReplenishmentRequestDto,
	UpdateReplenishmentRequestDto,
	CheckReplenishmentRequestDto,
	FilterReplenishmentRequestDto,
	ReplenishmentRequest,
	PaginatedReplenishmentRequests,
} from '../../types/finance/replenishmentRequest';
import { operationStatementsApi } from './operationStatements'

export const replenishmentRequestApi = createApi({
	reducerPath: 'replenishmentRequestApi',
	baseQuery: axiosBaseQuery({
		baseUrl: '/finance/replenishmentRequests',
	}),
	tagTypes: ['ReplenishmentRequests'],
	endpoints: (builder) => ({
		createReplenishmentRequest: builder.mutation<ReplenishmentRequest, CreateReplenishmentRequestDto>({
			query: (body) => ({
				url: `/`,
				method: 'POST',
				data: body,
			}),
			invalidatesTags: ['ReplenishmentRequests'],
		}),
		updateReplenishmentRequest: builder.mutation<ReplenishmentRequest, { id: string; changes: UpdateReplenishmentRequestDto }>({
			query: ({ id, changes }) => ({
				url: `/${id}`,
				method: 'PATCH',
				data: changes,
			}),
			invalidatesTags: ['ReplenishmentRequests'],
		}),
		checkReplenishmentRequest: builder.mutation<
			ReplenishmentRequest,
			{ id: string; checkDto: CheckReplenishmentRequestDto }
		>({
			query: ({ id, checkDto }) => ({
				url: `/${id}/check`,
				method: 'PATCH',
				data: checkDto,
			}),
			onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
				try {
					await queryFulfilled;
					dispatch(
						operationStatementsApi.util.invalidateTags(['OperationStatements'])
					);
				} catch (error) {
					console.error('Failed to check replenishment request:', error);
				}
			},
			invalidatesTags: ['ReplenishmentRequests']
		}),
		deleteReplenishmentRequest: builder.mutation<void, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['ReplenishmentRequests'],
		}),
		getAllReplenishmentRequests: builder.query<PaginatedReplenishmentRequests, FilterReplenishmentRequestDto>({
			query: (params) => ({
				url: `/`,
				method: 'GET',
				params,
			}),
			providesTags: ['ReplenishmentRequests'],
		}),
		getMyReplenishmentRequests: builder.query<ReplenishmentRequest[], void>({
			query: () => ({
				url: `/my`,
				method: 'GET',
			}),
			providesTags: ['ReplenishmentRequests'],
		}),
	}),
});

export const {
	useCreateReplenishmentRequestMutation,
	useUpdateReplenishmentRequestMutation,
	useCheckReplenishmentRequestMutation,
	useDeleteReplenishmentRequestMutation,
	useGetAllReplenishmentRequestsQuery,
	useGetMyReplenishmentRequestsQuery,
} = replenishmentRequestApi;
