import ExcelJS from 'exceljs';
import moment from 'moment';
import i18n from 'i18next';
import {
	AccountMonthlyRow,
	CashFlowReportResult,
	CashFlowRow,
	ReportNode,
	ReportResponse
} from '../types/finance/reports';
import { formatNumber } from './number';
import { currencySign } from '../types/finance/currency';
import { Buffer } from 'buffer';
import { downloadBlobFile } from './file';

/* ===========================
   CONSTANTS
========================== */
const CURRENCY_MANAGEMENT = 'USD';
const CURRENCY_NATIVE = 'UAH';

/* ===========================
   TEXT KEYS
========================== */
const TEXT_KEYS = {
	account: 'account',
	cashFlowType: 'cashFlowType',
	systemNative: 'systemNative',
	managementCurrency: 'managementCurrency',
	openingBalance: 'openingBalance',
	income: 'income',
	expense: 'expense',
	closingBalance: 'closingBalance',
	total: 'total',
	type: 'type',
	incoming: 'incoming',
	outcoming: 'outcoming',
	starting_cash: 'starting_cash',
	closing_cash: 'closing_cash',
	turnover_report: 'turnover_report',
	net_settlement_report: 'net_settlement_report',
	cash_flow_report: 'cash_flow_report'
};

/* ===========================
   CONFIGURATION & STYLE CONSTANTS
========================== */
const CONFIG = {
	cashFlowWidths: { fixed: 20, data: 10 },
	accountWidths: { fixed: 40, data: 25 },
	rowHeights: { header1: 25, header2: 20, dataLeaf: 30, dataNonLeaf: 18, totals: 18 },
	// Do not explicitly set font size so default is used.
	HEADER_FONT: { bold: true, color: { argb: 'FF000000' } },
	HEADER_ALIGNMENT: { horizontal: 'center' as const, vertical: 'middle' as const, wrapText: true },
	// Currency cells: right aligned, no wrapping.
	DATA_ALIGNMENT_RIGHT: { horizontal: 'right' as const, vertical: 'middle' as const, wrapText: false },
	DATA_ALIGNMENT_LEFT: { horizontal: 'left' as const, vertical: 'middle' as const, wrapText: true },
	TOTALS_FONT: { bold: true },
	COLORS: {
		headerFill: 'BDD7EE',
		headerBorder: 'FFCCCCCC',
		dataAlternateFill: 'FFF2F2F2',
		dataBorder: 'FFD9D9D9',
		totalsFill: 'FFFFEB9C',
		totalsBorder: 'FFBFBFBF',
		totalsFont: 'FF000000'
	},
	BG: { info: 'FFD1ECF1', success: 'FFD4EDDA' },
	MANAGEMENT_CURRENCY: CURRENCY_MANAGEMENT,
	NATIVE_CURRENCY: CURRENCY_NATIVE
};

const BORDER_STYLE = {
	header: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.headerBorder } },
	data: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.dataBorder } },
	totals: { style: 'thin' as ExcelJS.BorderStyle, color: { argb: CONFIG.COLORS.totalsBorder } }
};

/* ===========================
   HELPER FUNCTIONS
========================== */
export function displayValue(val: number, currencyName?: string): string {
	return val === 0 ? '' : `${formatNumber(val)} ${currencySign(currencyName ?? CONFIG.MANAGEMENT_CURRENCY)}`;
}

const setCellBorder = (cell: ExcelJS.Cell, border: Partial<ExcelJS.Border>) => {
	cell.border = { top: border, left: border, bottom: border, right: border };
};

export const applyHeaderCellStyle = (cell: ExcelJS.Cell): void => {
	cell.font = CONFIG.HEADER_FONT;
	cell.alignment = CONFIG.HEADER_ALIGNMENT;
	cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.headerFill } };
	setCellBorder(cell, BORDER_STYLE.header);
};

const mergeAndStyleCells = (
	worksheet: ExcelJS.Worksheet,
	startRow: number,
	startCol: number,
	endRow: number,
	endCol: number,
	value: any,
	styleFn: (cell: ExcelJS.Cell) => void
) => {
	try {
		worksheet.mergeCells(startRow, startCol, endRow, endCol);
	} catch (e) {}
	const cell = worksheet.getCell(startRow, startCol);
	cell.value = value;
	styleFn(cell);
};

/* ===========================
   UTILITY FUNCTIONS
========================== */
export function groupMonthsByYear(months: { month: string; year: string }[]): { year: string; count: number }[] {
	const groups: { year: string; count: number }[] = [];
	months.forEach(m => {
		if (groups.length === 0 || groups[groups.length - 1].year !== m.year) {
			groups.push({ year: m.year, count: 1 });
		} else {
			groups[groups.length - 1].count++;
		}
	});
	return groups;
}

export function getNonEmptyMonthIndicesForRows(
	rows: { monthly: number[] }[],
	monthCount: number
): number[] {
	const indices: number[] = [];
	for (let i = 0; i < monthCount; i++) {
		if (rows.some(row => row.monthly[i] !== 0)) {
			indices.push(i);
		}
	}
	return indices;
}

export function flattenHierarchicalData(rows: CashFlowRow[]): CashFlowRow[] {
	return rows.reduce((acc, row) => {
		acc.push(row);
		if (row.children && row.children.length > 0) {
			acc.push(...flattenHierarchicalData(row.children));
		}
		return acc;
	}, [] as CashFlowRow[]);
}

export function getNonEmptyMonthIndicesForHierarchical(
	rows: CashFlowRow[],
	overallTotals: { monthly: number[]; starting: number[]; closing: number[] },
	monthCount: number
): number[] {
	const indices: number[] = [];
	for (let i = 0; i < monthCount; i++) {
		if (
			rows.some(row => row.monthly[i] !== 0) ||
			overallTotals.monthly[i] !== 0 ||
			overallTotals.starting[i] !== 0 ||
			overallTotals.closing[i] !== 0
		) {
			indices.push(i);
		}
	}
	return indices;
}

function generateMonthsArray(dateFrom: Date, dateTo: Date): { month: string; year: string }[] {
	const start = moment(dateFrom);
	const end = moment(dateTo);
	const monthCount = end.diff(start, 'months') + 1;
	const months: { month: string; year: string }[] = [];
	for (let i = 0; i < monthCount; i++) {
		const m = start.clone().add(i, 'months');
		months.push({ month: m.format('MMM'), year: m.format('YYYY') });
	}
	return months;
}

/* ===========================
   CASH FLOW EXPORT FUNCTIONS
========================== */

/**
 * Creates header rows for the hierarchical Cash Flow table.
 * Row 1:
 *   - Column 1 is merged vertically and displays i18n.t(TEXT_KEYS.cashFlowType)
 *   - Then the month columns are grouped by year.
 *   - The final cell spans headerColSpan columns and displays i18n.t(TEXT_KEYS.total)
 *
 * Row 2:
 *   - Displays month abbreviations (if showNative is true, each two‑cell group is merged).
 */
function createCashFlowHeaders(
	worksheet: ExcelJS.Worksheet,
	months: { month: string; year: string }[],
	nonEmptyIndices: number[],
	showNative: boolean,
	startRow: number
): { currentRow: number; colCount: number } {
	const filteredMonths = months.filter((_, idx) => nonEmptyIndices.includes(idx));
	const headerColSpan = showNative ? 2 : 1;
	const colCount = 1 + (filteredMonths.length + 1) * headerColSpan;
	const widths = [CONFIG.cashFlowWidths.fixed, ...Array(colCount - 1).fill(CONFIG.cashFlowWidths.data)];
	widths.forEach((w, idx) => worksheet.getColumn(idx + 1).width = w);

	const yearGroups = groupMonthsByYear(filteredMonths);
	let currentRow = startRow;

	// Header Row 1
	const headerRow1 = worksheet.getRow(currentRow);
	headerRow1.height = CONFIG.rowHeights.header1;
	worksheet.mergeCells(currentRow, 1, currentRow + 1, 1);
	const firstHeaderCell = worksheet.getCell(currentRow, 1);
	firstHeaderCell.value = i18n.t(TEXT_KEYS.cashFlowType, { defaultValue: 'Cash Flow Type' });
	applyHeaderCellStyle(firstHeaderCell);

	let colCursor = 2;
	yearGroups.forEach(group => {
		const startCol = colCursor;
		const endCol = colCursor + group.count * headerColSpan - 1;
		mergeAndStyleCells(worksheet, currentRow, startCol, currentRow, endCol, Number(group.year), applyHeaderCellStyle);
		colCursor = endCol + 1;
	});
	worksheet.mergeCells(currentRow, colCount - headerColSpan + 1, currentRow + 1, colCount);
	const totalHeaderCell = worksheet.getCell(currentRow, colCount - headerColSpan + 1);
	totalHeaderCell.value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' });
	applyHeaderCellStyle(totalHeaderCell);
	headerRow1.commit();

	// Header Row 2
	currentRow++;
	const headerRow2 = worksheet.getRow(currentRow);
	headerRow2.height = CONFIG.rowHeights.header2;
	if (showNative) {
		filteredMonths.forEach((m, idx) => {
			const startCol = 2 + idx * 2;
			worksheet.mergeCells(currentRow, startCol, currentRow, startCol + 1);
			const cell = worksheet.getCell(currentRow, startCol);
			cell.value = m.month;
			applyHeaderCellStyle(cell);
		});
	} else {
		filteredMonths.forEach((m, idx) => {
			const cell = worksheet.getCell(currentRow, idx + 2);
			cell.value = m.month;
			applyHeaderCellStyle(cell);
		});
	}
	headerRow2.commit();
	currentRow++;
	return { currentRow, colCount };
}

/**
 * Writes the hierarchical Cash Flow table.
 * If showNative is true then for each period two cells are written (management then native);
 * otherwise one cell is used.
 * In the summary row, management and native totals are output separately.
 */
function writeHierarchicalTableAt(
	worksheet: ExcelJS.Worksheet,
	report: CashFlowReportResult,
	months: { month: string; year: string }[],
	startRow: number,
	showNative: boolean
): number {
	const flatData = flattenHierarchicalData(report.hierarchical);
	const nonEmptyIndices = getNonEmptyMonthIndicesForHierarchical(flatData, report.overallTotals, months.length);
	const { currentRow, colCount } = createCashFlowHeaders(worksheet, months, nonEmptyIndices, showNative, startRow);
	let rowCursor = currentRow;
	const headerColSpan = showNative ? 2 : 1;
	const filteredMonths = months.filter((_, idx) => nonEmptyIndices.includes(idx));

	// Mark the first data row for zebra calculation.
	const dataStartRow = rowCursor;

	const processRow = (row: CashFlowRow, level: number): void => {
		const excelRow = worksheet.getRow(rowCursor);
		const isLeaf = !row.children || row.children.length === 0;
		excelRow.height = isLeaf ? CONFIG.rowHeights.dataLeaf : CONFIG.rowHeights.dataNonLeaf;
		const firstCell = excelRow.getCell(1);
		const indentValue = (row.children && row.children.length > 0) ? level : 0;
		firstCell.value = row.name;
		firstCell.alignment = { ...CONFIG.DATA_ALIGNMENT_LEFT, indent: indentValue };
		setCellBorder(firstCell, BORDER_STYLE.data);

		filteredMonths.forEach((_, idx) => {
			const monthIdx = nonEmptyIndices[idx];
			const startCol = 2 + idx * headerColSpan;
			if (showNative) {
				const cellMgm = excelRow.getCell(startCol);
				cellMgm.value = displayValue(row.monthly[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
				cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
				setCellBorder(cellMgm, BORDER_STYLE.data);
				const cellNative = excelRow.getCell(startCol + 1);
				cellNative.value = displayValue(row.nativeMonthly ? row.nativeMonthly[monthIdx] : 0, CONFIG.NATIVE_CURRENCY);
				cellNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
				setCellBorder(cellNative, BORDER_STYLE.data);
			} else {
				try {
					worksheet.mergeCells(rowCursor, startCol, rowCursor, startCol + headerColSpan - 1);
				} catch (e) {}
				const cell = excelRow.getCell(startCol);
				cell.value = displayValue(row.monthly[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
				cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
				setCellBorder(cell, BORDER_STYLE.data);
			}
		});
		const totalStartCol = colCount - headerColSpan + 1;
		if (showNative) {
			const cellTotalMgm = excelRow.getCell(totalStartCol);
			cellTotalMgm.value = displayValue(row.total, CONFIG.MANAGEMENT_CURRENCY);
			cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellTotalMgm, BORDER_STYLE.data);
			const cellTotalNative = excelRow.getCell(totalStartCol + 1);
			cellTotalNative.value = displayValue(row.nativeTotal ?? 0, CONFIG.NATIVE_CURRENCY);
			cellTotalNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellTotalNative, BORDER_STYLE.data);
		} else {
			try {
				worksheet.mergeCells(rowCursor, totalStartCol, rowCursor, totalStartCol + headerColSpan - 1);
			} catch (e) {}
			const cellTotal = excelRow.getCell(totalStartCol);
			cellTotal.value = displayValue(row.total, CONFIG.MANAGEMENT_CURRENCY);
			cellTotal.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellTotal, BORDER_STYLE.data);
		}
		excelRow.outlineLevel = level;
		if (level > 0) excelRow.hidden = true;
		// Apply zebra fill based on the data row index.
		if ((rowCursor - dataStartRow) % 2 === 0) {
			excelRow.eachCell({ includeEmpty: true }, (cell) => {
				cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.dataAlternateFill } };
			});
		}
		excelRow.commit();
		rowCursor++;
		if (row.children && row.children.length > 0) {
			row.children.forEach(child => processRow(child, level + 1));
		}
	};
	report.hierarchical.forEach(node => processRow(node, 0));

	// Summary row
	let summaryRow = worksheet.getRow(rowCursor);
	summaryRow.height = CONFIG.rowHeights.totals;
	summaryRow.getCell(1).value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' });
	if (showNative) {
		for (let i = 0; i < filteredMonths.length; i++) {
			const startCol = 2 + i * 2;
			const cellMgm = summaryRow.getCell(startCol);
			cellMgm.value = displayValue(report.overallTotals.monthly[nonEmptyIndices[i]] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellMgm, BORDER_STYLE.data);
			const cellNative = summaryRow.getCell(startCol + 1);
			cellNative.value = displayValue(
				report.overallTotals.nativeMonthly ? report.overallTotals.nativeMonthly[nonEmptyIndices[i]] : 0,
				CONFIG.NATIVE_CURRENCY
			);
			cellNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellNative, BORDER_STYLE.data);
		}
		const cellTotalMgm = summaryRow.getCell(colCount - 1);
		cellTotalMgm.value = displayValue(report.overallTotals.total, CONFIG.MANAGEMENT_CURRENCY);
		cellTotalMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
		setCellBorder(cellTotalMgm, BORDER_STYLE.data);
		const cellTotalNative = summaryRow.getCell(colCount);
		cellTotalNative.value = displayValue(report.overallTotals.nativeTotal ?? 0, CONFIG.NATIVE_CURRENCY);
		cellTotalNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
		setCellBorder(cellTotalNative, BORDER_STYLE.data);
	} else {
		for (let i = 0; i < filteredMonths.length; i++) {
			const cell = summaryRow.getCell(i + 2);
			cell.value = displayValue(report.overallTotals.monthly[nonEmptyIndices[i]] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cell, BORDER_STYLE.data);
		}
		const totalStartCol = colCount - headerColSpan + 1;
		try {
			worksheet.mergeCells(rowCursor, totalStartCol, rowCursor, colCount);
		} catch (e) {}
		const cellTotal = summaryRow.getCell(totalStartCol);
		cellTotal.value = displayValue(report.overallTotals.total, CONFIG.MANAGEMENT_CURRENCY);
		cellTotal.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
		setCellBorder(cellTotal, BORDER_STYLE.data);
	}
	summaryRow.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT;
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } };
		setCellBorder(cell, BORDER_STYLE.totals);
	});
	summaryRow.commit();
	rowCursor++;

	let startingRow = worksheet.getRow(rowCursor);
	startingRow.height = CONFIG.rowHeights.totals;
	startingRow.getCell(1).value = i18n.t(TEXT_KEYS.starting_cash, { defaultValue: 'Starting Cash' });
	if (showNative) {
		for (let i = 0; i < filteredMonths.length; i++) {
			const monthIdx = nonEmptyIndices[i];
			const startCol = 2 + i * 2;
			const cellMgm = startingRow.getCell(startCol);
			cellMgm.value = displayValue(report.overallTotals.starting[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellMgm, BORDER_STYLE.data);
			const cellNative = startingRow.getCell(startCol + 1);
			cellNative.value = displayValue(
				report.overallTotals.nativeStarting ? report.overallTotals.nativeStarting[monthIdx] : 0,
				CONFIG.NATIVE_CURRENCY
			);
			cellNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellNative, BORDER_STYLE.data);
		}
		startingRow.getCell(colCount - 1).value = '';
		startingRow.getCell(colCount).value = '';
	} else {
		for (let i = 0; i < filteredMonths.length; i++) {
			const monthIdx = nonEmptyIndices[i];
			const cell = startingRow.getCell(2 + i);
			cell.value = displayValue(report.overallTotals.starting[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cell, BORDER_STYLE.data);
		}
		startingRow.getCell(colCount).value = '';
	}
	startingRow.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT;
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.BG.info } };
		setCellBorder(cell, BORDER_STYLE.totals);
	});
	startingRow.commit();
	rowCursor++;

	let closingRow = worksheet.getRow(rowCursor);
	closingRow.height = CONFIG.rowHeights.totals;
	closingRow.getCell(1).value = i18n.t(TEXT_KEYS.closing_cash, { defaultValue: 'Closing Cash' });
	if (showNative) {
		for (let i = 0; i < filteredMonths.length; i++) {
			const monthIdx = nonEmptyIndices[i];
			const startCol = 2 + i * 2;
			const cellMgm = closingRow.getCell(startCol);
			cellMgm.value = displayValue(report.overallTotals.closing[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cellMgm.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellMgm, BORDER_STYLE.data);
			const cellNative = closingRow.getCell(startCol + 1);
			cellNative.value = displayValue(
				report.overallTotals.nativeClosing ? report.overallTotals.nativeClosing[monthIdx] : 0,
				CONFIG.NATIVE_CURRENCY
			);
			cellNative.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cellNative, BORDER_STYLE.data);
		}
		closingRow.getCell(colCount - 1).value = '';
		closingRow.getCell(colCount).value = '';
	} else {
		for (let i = 0; i < filteredMonths.length; i++) {
			const monthIdx = nonEmptyIndices[i];
			const cell = closingRow.getCell(2 + i);
			cell.value = displayValue(report.overallTotals.closing[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cell.alignment = CONFIG.DATA_ALIGNMENT_RIGHT;
			setCellBorder(cell, BORDER_STYLE.data);
		}
		closingRow.getCell(colCount).value = '';
	}
	closingRow.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT;
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.BG.success } };
		setCellBorder(cell, BORDER_STYLE.totals);
	});
	closingRow.commit();
	rowCursor++;

	worksheet.properties.outlineProperties = { summaryBelow: false, summaryRight: false };
	return rowCursor;
}

/**
 * Writes the Account Monthly Totals table.
 * The header uses only i18n.t(TEXT_KEYS.account) for the first cell.
 */
function writeAccountMonthlyTableAt(
	worksheet: ExcelJS.Worksheet,
	accountData: AccountMonthlyRow[],
	months: { month: string; year: string }[],
	startRow: number
): number {
	const nonEmptyIndices = getNonEmptyMonthIndicesForRows(accountData, months.length);
	const filteredMonths = months.filter((_, idx) => nonEmptyIndices.includes(idx));
	const yearGroups = groupMonthsByYear(filteredMonths);

	const totalColCount = worksheet.columns.length;
	for (let idx = 1; idx <= totalColCount; idx++) {
		worksheet.getColumn(idx).width = idx === 1 ? CONFIG.accountWidths.fixed : CONFIG.accountWidths.data;
	}

	let rowCursor = startRow;
	const headerRow1 = worksheet.getRow(rowCursor);
	headerRow1.height = CONFIG.rowHeights.header1;
	headerRow1.getCell(1).value = i18n.t(TEXT_KEYS.account, { defaultValue: 'Account' });
	applyHeaderCellStyle(headerRow1.getCell(1));
	try {
		worksheet.mergeCells(rowCursor, 1, rowCursor + 1, 1);
	} catch (e) {}
	let colCursor = 2;
	yearGroups.forEach(group => {
		const startCol = colCursor;
		const endCol = colCursor + group.count - 1;
		mergeAndStyleCells(worksheet, rowCursor, startCol, rowCursor, endCol, Number(group.year), applyHeaderCellStyle);
		colCursor = endCol + 1;
	});
	headerRow1.commit();

	// Header Row 2.
	rowCursor++;
	const headerRow2 = worksheet.getRow(rowCursor);
	filteredMonths.forEach((m, idx) => {
		const cell = headerRow2.getCell(idx + 2);
		cell.value = m.month;
		cell.alignment = CONFIG.HEADER_ALIGNMENT;
		applyHeaderCellStyle(cell);
	});
	headerRow2.commit();
	rowCursor++;

	// Mark the first data row for zebra calculation.
	const dataStartRow = rowCursor;

	// Data rows.
	accountData.forEach(data => {
		const row = worksheet.getRow(rowCursor);
		row.height = CONFIG.rowHeights.dataLeaf;
		row.getCell(1).value = data.accountName;
		row.getCell(1).alignment = CONFIG.DATA_ALIGNMENT_LEFT;
		setCellBorder(row.getCell(1), BORDER_STYLE.data);
		filteredMonths.forEach((_, idx) => {
			const monthIdx = nonEmptyIndices[idx];
			const cell = row.getCell(idx + 2);
			cell.value = displayValue(data.monthly[monthIdx] || 0, CONFIG.MANAGEMENT_CURRENCY);
			cell.alignment = { ...CONFIG.DATA_ALIGNMENT_RIGHT, wrapText: false };
			setCellBorder(cell, BORDER_STYLE.data);
		});
		// Use the row's position relative to the first data row for zebra fill.
		if ((rowCursor - dataStartRow) % 2 === 0) {
			row.eachCell(cell => {
				cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.dataAlternateFill } };
			});
		}
		row.commit();
		rowCursor++;
	});

	// Totals Row.
	const totalsRowExcel = worksheet.getRow(rowCursor);
	totalsRowExcel.height = CONFIG.rowHeights.totals;
	totalsRowExcel.getCell(1).value = i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' });
	const totals: number[] = Array(filteredMonths.length).fill(0);
	accountData.forEach(data => {
		for (let i = 0; i < filteredMonths.length; i++) {
			totals[i] += data.monthly[nonEmptyIndices[i]] || 0;
		}
	});
	filteredMonths.forEach((_, idx) => {
		const cell = totalsRowExcel.getCell(idx + 2);
		cell.value = displayValue(totals[idx], CONFIG.MANAGEMENT_CURRENCY);
		cell.alignment = { ...CONFIG.DATA_ALIGNMENT_RIGHT, wrapText: false };
		setCellBorder(cell, BORDER_STYLE.data);
	});
	totalsRowExcel.eachCell(cell => {
		cell.font = CONFIG.TOTALS_FONT;
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } };
		setCellBorder(cell, BORDER_STYLE.totals);
	});
	totalsRowExcel.commit();
	rowCursor++;

	return rowCursor;
}

/* ===========================
   REPORT PARSERS & EXCEL EXPORT FUNCTIONS
========================== */
type GetValuesFn = (node: ReportNode) => {
	openingNative: number;
	incomingNative: number;
	outgoingNative: number;
	closingNative: number;
	openingMgm: number;
	incomingMgm: number;
	outgoingMgm: number;
	closingMgm: number;
	nativeCurrency: string;
};

interface WorksheetHeaders {
	firstColLabel: string;
	group1: string;
	group2: string;
	subHeaders: string[];
}

interface GenericParserOpts {
	tree: ReportNode[];
	totals: ReportResponse['totals'];
	getValues: GetValuesFn;
	headersConfig: WorksheetHeaders;
}

const processNode = (
	node: ReportNode,
	level: number,
	getValues: GetValuesFn,
	dataRows: string[][],
	rowLevels: number[],
	showNative: boolean
) => {
	const indent = (node.children && node.children.length > 0) ? '\u2003'.repeat(level) : '';
	const label = indent + node.name;
	const values = getValues(node);
	if (showNative) {
		dataRows.push([
			label,
			displayValue(values.openingNative, values.nativeCurrency),
			displayValue(values.incomingNative, values.nativeCurrency),
			displayValue(values.outgoingNative, values.nativeCurrency),
			displayValue(values.closingNative, values.nativeCurrency),
			displayValue(values.openingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.incomingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.outgoingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.closingMgm, CURRENCY_MANAGEMENT)
		]);
	} else {
		dataRows.push([
			label,
			displayValue(values.openingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.incomingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.outgoingMgm, CURRENCY_MANAGEMENT),
			displayValue(values.closingMgm, CURRENCY_MANAGEMENT)
		]);
	}
	rowLevels.push(level);
	node.children.forEach(child => processNode(child, level + 1, getValues, dataRows, rowLevels, showNative));
};

const parseReportTable = ({ tree, totals, getValues, headersConfig }: GenericParserOpts, showNative: boolean) => {
	const dataRows: string[][] = [];
	const rowLevels: number[] = [];
	tree.forEach(node => processNode(node, 0, getValues, dataRows, rowLevels, showNative));
	let totalsRow: string[];
	if (showNative) {
		totalsRow = [
			i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' }),
			displayValue(totals.openingNativeConv, CURRENCY_NATIVE),
			displayValue(totals.incomingNativeConv, CURRENCY_NATIVE),
			displayValue(totals.outgoingNativeConv, CURRENCY_NATIVE),
			displayValue(totals.closingNativeConv, CURRENCY_NATIVE),
			displayValue(totals.openingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.incomingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.outgoingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.closingMgm, CURRENCY_MANAGEMENT)
		];
	} else {
		totalsRow = [
			i18n.t(TEXT_KEYS.total, { defaultValue: 'Total' }),
			displayValue(totals.openingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.incomingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.outgoingMgm, CURRENCY_MANAGEMENT),
			displayValue(totals.closingMgm, CURRENCY_MANAGEMENT)
		];
	}
	return { headers: headersConfig, dataRows, rowLevels, totalsRow };
};

export const parseTurnoversReport = (
	finalReport: ReportResponse,
	isAccountsMode: boolean,
	showNative: boolean
) => {
	const headersConfig: WorksheetHeaders = {
		firstColLabel:
			i18n.t(TEXT_KEYS.account, { defaultValue: 'Account' }) +
			' / ' +
			i18n.t(TEXT_KEYS.cashFlowType, { defaultValue: 'Cash Flow Type' }),
		group1: showNative
			? i18n.t(TEXT_KEYS.systemNative, { defaultValue: 'System Native' })
			: i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' }),
		group2: showNative
			? i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' })
			: '',
		subHeaders: showNative
			? [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' }),
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
			: [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.income, { defaultValue: 'Income' }),
				i18n.t(TEXT_KEYS.expense, { defaultValue: 'Expense' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
	};

	const getValues: GetValuesFn = node => ({
		openingNative: showNative ? node.openingNative : 0,
		incomingNative: showNative ? node.incomingNative : 0,
		outgoingNative: showNative ? node.outgoingNative : 0,
		closingNative: showNative ? node.closingNative : 0,
		openingMgm: node.openingMgm,
		incomingMgm: node.incomingMgm,
		outgoingMgm: node.outgoingMgm,
		closingMgm: node.closingMgm,
		nativeCurrency: node.currency ?? ''
	});

	return parseReportTable({ tree: finalReport.tree, totals: finalReport.totals, getValues, headersConfig }, showNative);
};

export const parseNetSettlementReport = (
	finalReport: ReportResponse,
	showNative: boolean
) => {
	const headersConfig: WorksheetHeaders = {
		firstColLabel: i18n.t(TEXT_KEYS.type, { defaultValue: 'Type' }),
		group1: showNative
			? i18n.t(TEXT_KEYS.systemNative, { defaultValue: 'System Native' })
			: i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' }),
		group2: showNative
			? i18n.t(TEXT_KEYS.managementCurrency, { defaultValue: 'Management Currency' })
			: '',
		subHeaders: showNative
			? [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' }),
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
			: [
				'',
				i18n.t(TEXT_KEYS.openingBalance, { defaultValue: 'Opening Balance' }),
				i18n.t(TEXT_KEYS.incoming, { defaultValue: 'Incoming' }),
				i18n.t(TEXT_KEYS.outcoming, { defaultValue: 'Outcoming' }),
				i18n.t(TEXT_KEYS.closingBalance, { defaultValue: 'Closing Balance' })
			]
	};

	const getValues: GetValuesFn = node => ({
		openingNative: showNative ? node.openingNative : 0,
		incomingNative: showNative ? node.incomingNative : 0,
		outgoingNative: showNative ? node.outgoingNative : 0,
		closingNative: showNative ? node.closingNative : 0,
		openingMgm: node.openingMgm,
		incomingMgm: node.incomingMgm,
		outgoingMgm: node.outgoingMgm,
		closingMgm: node.closingMgm,
		nativeCurrency: 'UAH'
	});

	return parseReportTable({ tree: finalReport.tree, totals: finalReport.totals, getValues, headersConfig }, showNative);
};

/* ===========================
   EXCEL EXPORT & DOWNLOAD FUNCTIONS
========================== */

/**
 * Creates header rows for generic reports (Turnover/Net Settlement) similar to the Cash Flow header.
 * @param worksheet - The ExcelJS worksheet
 * @param headersConfig - Configuration with firstColLabel, group1, group2 and subHeaders.
 * @param startRow - The starting row number for the header.
 * @returns The row number after the header rows.
 */
function createGenericReportHeaders(
	worksheet: ExcelJS.Worksheet,
	headersConfig: WorksheetHeaders,
	startRow: number
): number {
	// Determine total columns from subHeaders.
	const colCount = headersConfig.subHeaders.length;
	// Set widths for each column.
	const widths = [40, ...Array(colCount - 1).fill(18)];
	widths.forEach((w, idx) => worksheet.getColumn(idx + 1).width = w);

	// Header Row 1
	const headerRow1 = worksheet.getRow(startRow);
	headerRow1.height = CONFIG.rowHeights.header1;
	// Merge first cell (A1:A2) for the first column label.
	worksheet.mergeCells(startRow, 1, startRow + 1, 1);
	const firstCell = worksheet.getCell(startRow, 1);
	firstCell.value = headersConfig.firstColLabel;
	applyHeaderCellStyle(firstCell);

	if (!headersConfig.group2) {
		// Single group: merge from column 2 to last column.
		worksheet.mergeCells(startRow, 2, startRow, colCount);
		const groupCell = worksheet.getCell(startRow, 2);
		groupCell.value = headersConfig.group1;
		applyHeaderCellStyle(groupCell);
	} else {
		// Two groups: split remaining columns (columns 2 to colCount)
		const remaining = colCount - 1;
		const group1Cols = Math.floor(remaining / 2);
		worksheet.mergeCells(startRow, 2, startRow, 1 + group1Cols);
		const group1Cell = worksheet.getCell(startRow, 2);
		group1Cell.value = headersConfig.group1;
		applyHeaderCellStyle(group1Cell);
		worksheet.mergeCells(startRow, 2 + group1Cols, startRow, colCount);
		const group2Cell = worksheet.getCell(startRow, 2 + group1Cols);
		group2Cell.value = headersConfig.group2;
		applyHeaderCellStyle(group2Cell);
	}
	headerRow1.commit();

	// Header Row 2 (Subheaders)
	const headerRow2 = worksheet.getRow(startRow + 1);
	headerRow2.height = CONFIG.rowHeights.header2;
	// Skip the first column since it's merged with the first header row.
	for (let i = 1; i < colCount; i++) {
		const cell = headerRow2.getCell(i + 1);
		cell.value = headersConfig.subHeaders[i];
		applyHeaderCellStyle(cell);
	}
	headerRow2.commit();

	return startRow + 2;
}

const generateExcelReport = async (
	sheetName: string,
	headersConfig: { firstColLabel: string; group1: string; group2: string; subHeaders: string[] },
	dataRows: string[][],
	rowLevels: number[],
	totalsRow: string[],
	filePrefix: string,
	dateFrom: Date,
	dateTo: Date
): Promise<{ fileName: string; buffer: Buffer }> => {
	const startDateStr = moment(dateFrom).format('DD.MM.YYYY');
	const endDateStr = moment(dateTo).format('DD.MM.YYYY');
	const fileName = `${filePrefix}_(${startDateStr}_${endDateStr}).xlsx`;
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(sheetName);
	const startRow = createGenericReportHeaders(worksheet, headersConfig, 1);
	let rowCursor = startRow;
	// Store the first data row number for zebra calculation.
	const dataStartRow = rowCursor;

	dataRows.forEach((rowData, i) => {
		const row = worksheet.getRow(rowCursor);
		row.values = rowData;
		row.outlineLevel = rowLevels[i];
		if (row.outlineLevel > 0) row.hidden = true;
		// Compute the local index (ignoring headers) for zebra fill.
		const localIndex = rowCursor - dataStartRow;
		if (localIndex % 2 === 0) {
			row.eachCell({ includeEmpty: true }, (cell) => {
				cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.dataAlternateFill } };
			});
		}
		row.eachCell({ includeEmpty: true }, (cell, col) => {
			if (!cell.alignment) {
				cell.alignment = { wrapText: col === 1, horizontal: col === 1 ? 'left' : 'right', vertical: 'middle' };
			}
			setCellBorder(cell, BORDER_STYLE.data);
		});
		row.commit();
		rowCursor++;
	});

	const totalsExcelRow = worksheet.getRow(rowCursor);
	totalsExcelRow.values = totalsRow;
	totalsExcelRow.eachCell({ includeEmpty: true }, (cell, col) => {
		cell.font = { bold: true, color: { argb: CONFIG.COLORS.totalsFont } };
		cell.alignment = { wrapText: col === 1, horizontal: col === 1 ? 'left' : 'right', vertical: 'middle' };
		cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: CONFIG.COLORS.totalsFill } };
		setCellBorder(cell, BORDER_STYLE.totals);
	});
	totalsExcelRow.commit();

	worksheet.properties.outlineLevelRow = Math.max(...rowLevels, 0);
	worksheet.properties.outlineProperties = { summaryBelow: false, summaryRight: false };

	const arrayBuffer = await workbook.xlsx.writeBuffer();
	return { fileName, buffer: Buffer.from(arrayBuffer) };
};

export async function downloadTurnoversExcel(
	finalReport: ReportResponse,
	isAccountsMode: boolean,
	showNative: boolean,
	dateFrom: Date,
	dateTo: Date
): Promise<void> {
	const { headers, dataRows, rowLevels, totalsRow } = parseTurnoversReport(finalReport, isAccountsMode, showNative);
	const { fileName, buffer } = await generateExcelReport(
		'Turnover Data',
		headers,
		dataRows,
		rowLevels,
		totalsRow,
		i18n.t(TEXT_KEYS.turnover_report, { defaultValue: 'Turnover Report' }),
		dateFrom,
		dateTo
	);
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	downloadBlobFile(blob, fileName);
}

export async function downloadNetSettlementExcel(
	finalReport: ReportResponse,
	showNative: boolean,
	dateFrom: Date,
	dateTo: Date
): Promise<void> {
	const { headers, dataRows, rowLevels, totalsRow } = parseNetSettlementReport(finalReport, showNative);
	const { fileName, buffer } = await generateExcelReport(
		'Net Settlement',
		headers,
		dataRows,
		rowLevels,
		totalsRow,
		i18n.t(TEXT_KEYS.net_settlement_report, { defaultValue: 'Net Settlement Report' }),
		dateFrom,
		dateTo
	);
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	downloadBlobFile(blob, fileName);
}

export async function downloadCashFlowExcel(
	report: CashFlowReportResult,
	dateFrom: Date,
	dateTo: Date,
	showNative: boolean
): Promise<void> {
	const months = generateMonthsArray(dateFrom, dateTo);
	const workbook = new ExcelJS.Workbook();
	const worksheet = workbook.addWorksheet(
		i18n.t(TEXT_KEYS.cash_flow_report, { defaultValue: 'Cash Flow Report' }),
		{ views: [{ showGridLines: true }] }
	);
	let currentRow = 1;
	currentRow = writeHierarchicalTableAt(worksheet, report, months, currentRow, showNative);
	currentRow += 4;
	writeAccountMonthlyTableAt(worksheet, report.accountMonthly, months, currentRow);
	const fileName = `CashFlow_Report_(${moment(dateFrom).format('DD.MM.YYYY')}_${moment(dateTo).format('DD.MM.YYYY')}).xlsx`;
	const arrayBuffer = await workbook.xlsx.writeBuffer();
	const buffer = Buffer.from(arrayBuffer);
	const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
	downloadBlobFile(blob, fileName);
}
