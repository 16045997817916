import React, { useState } from 'react'
import UniversalTable from 'components/Tables/UniversalTable'

import 'moment/locale/ru'
import 'moment/locale/uk'
import { Badge } from 'react-bootstrap'
import TableError from '../../../../../components/TableError'

import Moment from 'react-moment'
import { useTranslation } from 'react-i18next'
import { useLoadProjectsListQuery } from '../../../../../redux/slices/projects'
import { ShortProject } from '../../../../../types/project'
import ProjectNameBadge from '../../../../../components/BagesAndLabels/ProjectNameBadge'
import Loader from '../../../../../components/Loader'

const columns = [
    {
        dataField: 'order_number',
        text: '№',
        sort: false,
        width: '5%',
        className: 'text-center'
    },
    {
        dataField: 'code',
        text: 'project_code',
        sort: false,
        width: '10%',
        className: 'text-center'
    },
    {
        dataField: 'name',
        text: 'name',
        sort: false,
        width: '20%',
        className: 'text-left'
    },
    {
        dataField: 'number',
        text: 'iteration_number',
        sort: false,
        width: '15%',
        className: 'text-center'
    },
    {
        dataField: 'type',
        text: 'type',
        sort: false,
        width: '25%',
        className: 'text-center'
    },
    {
        dataField: 'creationDate',
        text: 'creationDate',
        sort: true,
        width: '20%',
        className: 'text-center'
    }
]

interface ProjectRowProps {
    project: ShortProject;
    index: number;
}

function ProjectRow({ project, index } : ProjectRowProps) {
    const { t } = useTranslation()

    if (!project) return null

    return (
        <tr>
            <td className="text-center">{index + 1}</td>
            <td className="text-center">
                <Badge children={project.code} />
            </td>
            <td className="text-left">
                <ProjectNameBadge project={project} />
            </td>
            <td className="text-center">{project.iteration_number}</td>
            <td className="text-center">{t(project.project_type)}</td>
            <td className="text-center">
                <Moment format="DD.MM.YYYY" locale={t('locales')} date={project.creationDate} />
            </td>
        </tr>
    )
}

function ProjectsInfoTable() {
    const { t } = useTranslation()
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const { data, isLoading, isError } = useLoadProjectsListQuery({
        page: currentPage,
        limit: itemsPerPage,
    });

    const projectsInfo = data?.projects || [];
    const totalItems = data?.total || 0;

    if (isLoading) return <Loader />;
    if (isError) return <TableError>{t('request_error')}</TableError>;

    return projectsInfo && projectsInfo.length > 0 ?
        <UniversalTable
            name="ProjectsInfoTable_tableName"
            id="projectsInfo"
            columns={columns}
            data={[...projectsInfo]}
            row={(project, i) => <ProjectRow index={i} key={i + 'projectsInfoRow'} project={project} />}
            scrollable
            externalCurrentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            externalItemsPerPage={itemsPerPage}
            onItemsPerPageChange={setItemsPerPage}
            totalItems={totalItems}
            withPagination
        /> : <TableError>{t('no_data')}</TableError>
}

export default ProjectsInfoTable
