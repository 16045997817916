import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import {
	GetTurnoverReportArgs,
	GetNetSettlementReportArgs,
	ReportResponse, GetCashFlowReportArgs
} from '../../types/finance/reports'

export const reportsApi = createApi({
	reducerPath: 'reportsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `finance`,
	}),
	tagTypes: ['ReportsTurnovers', 'ReportsNetSettlement', 'ReportsCashFlow'],
	endpoints: (builder) => ({
		getTurnovers: builder.query<
			ReportResponse,
			GetTurnoverReportArgs
		>({
			query: (args) => ({
				url: `/reports/turnovers`,
				method: 'GET',
				params: args,
			}),
			providesTags: ['ReportsTurnovers'],
		}),
		getNetSettlement: builder.query<
			ReportResponse,
			GetNetSettlementReportArgs
		>({
			query: (args) => ({
				url: `/reports/net-settlement`,
				method: 'GET',
				params: args,
			}),
			providesTags: ['ReportsNetSettlement'],
		}),
		getCashFlow: builder.query<
			any,
			GetCashFlowReportArgs
		>({
			query: (args) => ({
				url: `/reports/cashFlow`,
				method: 'GET',
				params: args,
			}),
			providesTags: ['ReportsCashFlow'],
		}),
	}),
});


export const {
	useGetTurnoversQuery,
	useGetNetSettlementQuery,
	useGetCashFlowQuery
} = reportsApi;