import React, { useEffect, useState } from 'react'
import { ListItem } from '../../../../components/NestedList'
import {
    useLoadExpenseTypesQuery,
    useUpdateExpenseTypesMutation
} from '../../../../redux/slices/financeDictionaries';
import {ExpenseType} from "../../../../types/finance/expenseType";
import TableError from '../../../../components/TableError'
import NestedList2 from '../../../../components/NestedList2'
import { toast } from 'react-toastify'
import Loader from '../../../../components/Loader'

const parseToFlatFormat = (nestedData: ListItem[]): ExpenseType[] => {
    const flatData: ExpenseType[] = []

    const flatten = (items: ListItem[], parentId: string | null = null) => {
        items.forEach(item => {
            flatData.push({
                _id: item._id,
                parentType: parentId,
                ukName: item.ukName || '',
                enName: item.enName || '',
                isArchived: item.isArchived || false,
            })

            if (item.children && item.children.length > 0) {
                flatten(item.children, item._id)
            }
        })
    }

    flatten(nestedData)
    return flatData
}

function ExpenseTypes() {
    const [formatedData, setFormatedData] = useState<ListItem[]>([])

    const { data: loadedExpenseTypes, isLoading, isError } = useLoadExpenseTypesQuery();

    const [updateExpenseTypes] = useUpdateExpenseTypesMutation();

    useEffect(() => {
        if (loadedExpenseTypes) {
            setFormatedData(loadedExpenseTypes as ListItem[]);
        }
    }, [loadedExpenseTypes]);

    const onChange = async (data: ListItem[]) => {
        const formattedData = parseToFlatFormat(data);
        try {
            await updateExpenseTypes(formattedData).unwrap();
            // toast.success(t('expenseTypes_update_success') as string)
        } catch (error) {
            toast.error('Error updating expense types: ' + error);
        }
    };

    if (isLoading || formatedData.length === 0) return <Loader/>;
    if (isError) return <TableError>Error loading data</TableError>;

    return <NestedList2
        key={formatedData.toString()}
        initialData={formatedData}
        onDataChange={onChange}
        useTranslations={true}
        useArchived={true}
        colorSchema={8}
    />
}

export default ExpenseTypes