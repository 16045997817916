import React from 'react';
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CurrencyRates from './CurrencyRates';
import CashFlowTypes from './CashFlowTypes';
import ExpenseTypes from './ExpenseTypes';
import OrganizationsTable from './Tables/OrganizationsTable';
import Contractors from './Contractors';
import Accounts from './Accounts';
import { NavLink } from 'react-router-dom'
import Contracts from './Contracts'
import ProjectsTable from './Tables/ProjectsTable'
import InvoiceClassesTable from './Tables/InvoiceClassesTable'

function DictionariesContainer({children}) {
	const { t } = useTranslation();

	return (
		<Container fluid className="p-0">
			<Row className="mb-2 mb-xxl-4">
				<Col xs="auto" className="d-none d-sm-block">
					<h3>{t('Dictionaries_header')}</h3>
				</Col>
			</Row>
			<div className={'tab'}>
				<Tab.Container
					id="left-tabs-example"
				>
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/dictionaries/currency-rates"
							>
								{t('Dictionaries_exchangeRates')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/dictionaries/cash-flow-types"
							>
								{t('Dictionaries_cashFlowTypes')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/dictionaries/expenses-types"
							>
								{t('Dictionaries_expensesTypes')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/dictionaries/organizations"
							>
								{t('Dictionaries_organizations')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to="/finances/dictionaries/accounts">
								{t('Dictionaries_accounts')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/dictionaries/contractors"
							>
								{t('Dictionaries_contractors')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to="/finances/dictionaries/contracts">
								{t('Dictionaries_contracts')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to="/finances/dictionaries/projects">
								{t('Dictionaries_projects')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link as={NavLink} to="/finances/dictionaries/invoices">
								{t('Dictionaries_invoices')}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						{children}
					</Tab.Content>
				</Tab.Container>
			</div>
		</Container>
	);
}

export function CurrencyRatesTab() {
	return (
		<DictionariesContainer>
			<CurrencyRates/>
		</DictionariesContainer>
	)
}

export function CashFlowTypesTab() {
	return (
		<DictionariesContainer>
			<CashFlowTypes />
		</DictionariesContainer>
	)
}

export function ExpenseTypesTab() {
	return (
		<DictionariesContainer>
			<ExpenseTypes />
		</DictionariesContainer>
	)
}

export function OrganizationsTab() {
	return (
		<DictionariesContainer>
			<OrganizationsTable/>
		</DictionariesContainer>
	)
}

export function ContractorsTab() {
	return (
		<DictionariesContainer>
			<Contractors />
		</DictionariesContainer>
	)
}

export function AccountsTab() {
	return (
		<DictionariesContainer>
			<Accounts />
		</DictionariesContainer>
	)
}

export function ContractsTab() {
	return (
		<DictionariesContainer>
			<Contracts />
		</DictionariesContainer>
	)
}

export function ProjectsTab() {
	return (
		<DictionariesContainer>
			<ProjectsTable/>
		</DictionariesContainer>
	)
}

export function InvoicesTab() {
	return (
		<DictionariesContainer>
			<InvoiceClassesTable/>
		</DictionariesContainer>
	)
}