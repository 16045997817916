import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import {
	useArchiveContractorTypeMutation, useGetContractorTypesQuery,
	useUnarchiveContractorTypeMutation
} from '../../../../../redux/slices/financeDictionaries'

import { FaArchive } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { ContractorType } from '../../../../../types/finance/contractor'
import UpdateContractorTypeModal from '../Modals/UpdateContractorTypeModal'
import TableError from '../../../../../components/TableError'
import { RolesEnum } from '../../../../../types/roles'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import DangerModal from '../../../../../components/Modals/DangerModal'
import Loader from '../../../../../components/Loader'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface ContractorTypeRowProps {
	contractorType: ContractorType;
	handlers: any;
	index: number;
}

function ContractorTypeRow({ contractorType, handlers, index }: ContractorTypeRowProps) {
	const { i18n } = useTranslation();

	const [archiveContractorType, unarchiveContractorType] = handlers

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? contractorType.ukName : contractorType.enName)}</td>
			<td className="text-center">
				<div className="d-flex align-middle justify-content-center">
					{
						!contractorType.isArchived && (
							<UpdateContractorTypeModal contractorType={contractorType} />
						)
					}
					{
						contractorType.isArchived ? (
							<SuccessModal
								id={contractorType._id}
								title={'unarchive'}
								icon={FaArrowRotateLeft}
								onAccept={() => unarchiveContractorType(contractorType._id)}
								tooltipText={'unarchive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						) : (
							<DangerModal
								id={contractorType._id}
								title={'archive'}
								onAccept={() => archiveContractorType(contractorType._id)}
								icon={FaArchive}
								tooltipText={'archive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractorTypesTable() {
	const { t } = useTranslation()

	const [archiveContractorType] = useArchiveContractorTypeMutation()
	const [unarchiveContractorType] = useUnarchiveContractorTypeMutation()

	const { data: contractorTypesData, isLoading, isError } = useGetContractorTypesQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedContractorTypesData = (contractorTypesData && contractorTypesData.filter(entry => !entry.isArchived)) || []
	const archivedContractorTypesData = (contractorTypesData && contractorTypesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractorType, unarchiveContractorType]

	return <>
		{
			unarchivedContractorTypesData && unarchivedContractorTypesData.length > 0 ?
				<UniversalTable
					name={'ContractorTypesTable_tableName'}
					id={'contractorsTable ContractorTypesTable_tableName'}
					columns={columns}
					data={unarchivedContractorTypesData}
					row={
						(contractorType, i) =>
							<ContractorTypeRow handlers={handlers} key={contractorType._id + 'contractorTypeRow'} contractorType={contractorType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : <TableError>{t('no_data')}</TableError>
		}
		{
			archivedContractorTypesData && archivedContractorTypesData.length > 0 ?
				<UniversalTable
					name={'ContractorTypesTable_tableNameArchived'}
					id={'contractorsTable ContractorTypesTable_tableNameArchived'}
					columns={columns}
					data={archivedContractorTypesData}
					row={
						(contractorType, i) =>
							<ContractorTypeRow handlers={handlers} key={contractorType._id + 'contractorTypeRow'} contractorType={contractorType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> : null
		}
	</>
}

export default ContractorTypesTable;
