import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';

import { RolesEnum } from '../../../../../types/roles';
import { useCreateInvoiceClassMutation } from '../../../../../redux/slices/invoices'
import ModalView from '../../../../../components/Modals/ModalView'
import { InputFormGroup } from '../../../../../components/FormGroups'

const CreateInvoiceClassModal = () => {
	const { t } = useTranslation();

	const [enName, setEnName] = useState('');
	const [ukName, setUkName] = useState('');

	const [enNameError, setEnNameError] = useState(false);
	const [ukNameError, setUkNameError] = useState(false);

	const [addContractorClass] = useCreateInvoiceClassMutation();

	const handleCreateInvoiceClass = async () => {
		let isValid = true;

		// Set error flags based on validation
		setUkNameError(!ukName);
		setEnNameError(!enName)

		if (!ukName || !enName) {
			isValid = false;
		}

		if (!isValid) return false;

		const newClass = {
			enName,
			ukName
		};

		try {
			await addContractorClass(newClass);
			clearForm();
			return true;
		} catch (error) {
			console.error("Error creating invoice class:", error);
			return false;
		}
	};

	const clearForm = () => {
		setUkName('');
		setEnName('');
	};

	return (
		<ModalView
			title={'CreateInvoiceClass_title'}
			tooltipText={'CreateInvoiceClassModal_tooltipText'}
			id="CreateNewInvoiceClass"
			buttonIcon={FaPlus}
			onAccept={handleCreateInvoiceClass}
			clearFunction={clearForm}
			acceptButtonText={'CreateInvoiceClassModal_createButton'}
			rejectButtonText={'cancelButton'}
			buttonText={'CreateInvoiceClassModal_createButton'}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('ukName')}
						value={ukName}
						onChange={(e) => {
							setUkName(e.target.value);
							setUkNameError(false);
						}}
						error={ukNameError}
					/>
				</Col>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('enName')}
						value={enName}
						onChange={(e) => {
							setEnName(e.target.value);
							setEnNameError(false);
						}}
						error={enNameError}
					/>
				</Col>
			</Row>
		</ModalView>
	);
};

export default CreateInvoiceClassModal;
