import { Col, Row } from 'react-bootstrap'
import React from 'react'
import CreateContractModal from './Modals/CreateContractModal'
import ContractsTable from './Tables/ContractsTable'
import CreateContractTypeModal from './Modals/CreateContractTypeModal'
import ContractTypesTable from './Tables/ContractTypesTable'

function Contracts() {
	return (
		<>
			<Row className="mb-3">
				<Col xs="auto" className="ml-auto text-right">
					<CreateContractModal />
				</Col>
			</Row>
			<ContractsTable/>
			<Row className="my-2 mb-xxl-4">
				<Col xs={12} md={6}>
					<Row className="my-4">
						<Col xs="auto" className="ml-auto text-right mt-n1">
							<CreateContractTypeModal />
						</Col>
					</Row>
					<ContractTypesTable/>
				</Col>
			</Row>
		</>
	)
}

export default Contracts