import { configureStore } from '@reduxjs/toolkit'
import { UserState } from 'redux/reducers/userReducer'
import rootReducer from '../reducers/index'
import { financeDictionariesApi } from '../slices/financeDictionaries'
import { rtkQueryErrorLogger } from '../middlewares/rtkQueryErrorLogger'
import { projectsApi } from '../slices/projects'
import { publishersApi } from '../slices/publishers'
import { operationStatementsApi } from '../slices/operationStatements'
import { invoicesApi } from '../slices/invoices'
import { salariesTableApi } from '../slices/salariesTable'
import { reportsApi } from '../slices/reports'
import { systemSettingsApi } from '../slices/systemSettings'
import { replenishmentRequestApi } from '../slices/replenishmentRequests'

const store = configureStore({
	reducer: rootReducer,
	devTools: process.env.NODE_ENV === 'development',
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
			serializableCheck: false,
		})
			.concat(projectsApi.middleware)
			.concat(publishersApi.middleware)
			.concat(financeDictionariesApi.middleware)
			.concat(operationStatementsApi.middleware)
			.concat(invoicesApi.middleware)
			.concat(salariesTableApi.middleware)
			.concat(reportsApi.middleware)
			.concat(systemSettingsApi.middleware)
			.concat(replenishmentRequestApi.middleware)
			.concat(rtkQueryErrorLogger)
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

// Define the store type to match your structure
export interface StoreType {
	theme: any;
	data: any;
	user: UserState;

	publishersApi: ReturnType<typeof publishersApi.reducer>;
	financeApi: ReturnType<typeof financeDictionariesApi.reducer>;
	projectsApi: ReturnType<typeof projectsApi.reducer>;
	operationStatementsApi: ReturnType<typeof operationStatementsApi.reducer>;
	invoicesApi: ReturnType<typeof invoicesApi.reducer>;
	salariesTableApi: ReturnType<typeof salariesTableApi.reducer>;
	reportsApi: ReturnType<typeof reportsApi.reducer>;
	replenishmentRequestApi: ReturnType<typeof replenishmentRequestApi.reducer>;
	systemSettingsApi: ReturnType<typeof systemSettingsApi.reducer>;
}
