import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup, SingleselectFormGroup } from 'components/FormGroups';
import ModalView from 'components/Modals/ModalView';
import { FaBuilding, FaClock, FaPlus } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';
import {
	useAddContractsMutation,
	useLoadOrganizationsSelectQuery,
	useLoadContractorsSelectQuery,
	useGetUnarchivedContractTypesQuery
} from '../../../../../redux/slices/financeDictionaries'
import SelectOption from '../../../../../types/selectOption';
import { RolesEnum } from '../../../../../types/roles';
import { FaBriefcase, FaHandshake, FaList } from 'react-icons/fa6'
import moment from 'moment'
import { Conditions, Postponement } from '../../../../../types/finance/contract'
import Calendar from '../../../../../components/Calendar'

const CreateContractModal = () => {
	const { t, i18n } = useTranslation();

	const [organization, setOrganization] = useState<SelectOption>();
	const [contractor, setContractor] = useState<SelectOption>();
	const [type, setType] = useState<SelectOption>();
	const [name, setName] = useState('');
	const [creationDate, setCreationDate] = useState<Date>(new Date())

	const [conditions, setConditions] = useState<SelectOption>();
	const [postponement, setPostponement] = useState<SelectOption>()

	const [organizationError, setOrganizationError] = useState(false);
	const [contractorError, setContractorError] = useState(false);
	const [creationDateError, setCreationDateError] = useState(false);
	const [typeError, setTypeError] = useState(false);
	const [nameError, setNameError] = useState(false);

	const { data: organizations = [] } = useLoadOrganizationsSelectQuery();
	const { data: contractors = [] } = useLoadContractorsSelectQuery();
	const { data: contractTypes = [] } = useGetUnarchivedContractTypesQuery();

	const [addContract] = useAddContractsMutation();

	const handleCreateContract = async () => {
		let isValid = true;

		// Set error flags based on validation
		setNameError(!name);
		setOrganizationError(!organization)
		setContractorError(!contractor)
		setCreationDateError(!creationDate)
		setTypeError(!type);

		if (!name || !type || !organization || !postponement || !creationDate) {
			isValid = false;
		}

		if (!isValid) return false;

		const selectedType = contractTypes.find(conType => conType._id === type?.value);
		const selectedOrganization = organizations.find(org => org._id === organization?.value)
		const selectedContractor = contractors.find(cont => cont._id === contractor?.value)

		if (!selectedOrganization || !selectedType || !selectedContractor) {
			console.error("Invalid selections. Please select valid class and type.");
			setOrganizationError(!selectedOrganization)
			setContractorError(!selectedContractor)
			setTypeError(!selectedType);
			return false;
		}

		const newContract = {
			name,
			type: selectedType._id,
			organization: selectedOrganization._id,
			contractor: selectedContractor._id,
			creationDate: moment(creationDate).toISOString(),
			conditions: conditions?.value,
			postponement: postponement?.value
		};

		try {
			await addContract(newContract);
			clearForm();
			return true;
		} catch (error) {
			console.error("Error creating contract:", error);
			return false;
		}
	};

	const clearForm = () => {
		setName('');
		setType(undefined);
		setOrganization(undefined)
		setContractor(undefined)
		setConditions(undefined)
		setPostponement(undefined)

		setNameError(false);
		setTypeError(false);
		setContractorError(false);
		setOrganizationError(false);
	};

	return (
		<ModalView
			title={'CreateContractModal_title'}
			tooltipText={'CreateContractModal_tooltipText'}
			id="CreateNewContract"
			buttonIcon={FaPlus}
			onAccept={handleCreateContract}
			clearFunction={clearForm}
			acceptButtonText={'CreateContractModal_createButton'}
			rejectButtonText={'cancelButton'}
			buttonText={'CreateContractModal_createButton'}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('contractName')}
						value={name}
						onChange={(e) => {
							setName(e.target.value);
							setNameError(false);
						}}
						error={nameError}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('organization')}
						options={
							organizations.map((org, index) => ({ label: org.name, value: org._id, index }))
						}
						value={organization}
						onChange={(selected) => {
							setOrganization(selected);
							setOrganizationError(false);
						}}
						error={organizationError}
						prefix={<FaBuilding className="w-100 h-100" />}
					/>
				</Col>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('contractor')}
						options={
							contractors.map((contractor, index) =>
								({ label: contractor.name, value: contractor._id, index}))
						}
						value={contractor}
						onChange={(selected) => {
							setContractor(selected);
							setContractorError(false);
						}}
						error={contractorError}
						prefix={<FaBriefcase className="w-100 h-100" />}
					/>
				</Col>
			</Row>

			<Row>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('contractType')}
						options={
							contractTypes.map((type, index) =>
								({ label: (i18n.language === 'uk' ? type.ukName : type.enName), value: type._id, index }))
						}
						value={type}
						onChange={(selected) => {
							setType(selected);
							setTypeError(false);
						}}
						error={typeError}
						prefix={<FaList className="w-100 h-100" />}
					/>
				</Col>
				<Col md={6}>
					<Calendar
						label="CreateContractModal_calendarLabel"
						value={creationDate}
						onChange={setCreationDate}
						error={creationDateError}
					/>
				</Col>
			</Row>

			<Row className="mb-3">
				<Col md={6}>
					<SingleselectFormGroup
						label={t('conditions')}
						options={
							(Object.keys(Conditions) as Array<keyof typeof Conditions>).map((key, index) => {
								return {label: t(key), value: key, index}
							})
						}
						value={conditions}
						onChange={(selected) => {
							setConditions(selected);
						}}
						prefix={<FaHandshake className="w-100 h-100" />}
					/>
				</Col>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('postponement')}
						options={
							(Object.keys(Postponement) as Array<keyof typeof Postponement>).map((key, index) => {
								return {label: Postponement[key], value: Postponement[key], index}
							})
						}
						value={postponement}
						onChange={(selected) => {
							setPostponement(selected);
						}}
						prefix={<FaClock className="w-100 h-100" />}
					/>
				</Col>
			</Row>
		</ModalView>
	);
};

export default CreateContractModal;
