import { PositionsEnum } from 'types/positions'
import { RolesEnum } from 'types/roles'

export enum ColorSchemeEnum {
	Rainbow = 'rainbow',
	Platform = 'platform'
}

// returns raw color name
export const rainbowColorNameAtPosition = (position: PositionsEnum, roles: RolesEnum[]) => {
	const index = rainbowColorNameAtPositionIndex(position, roles)
	return rainbowColorNames(index)
}

// returns hex color with alpha value
export const rainbowHexColorNameAtPosition = (
	position: PositionsEnum,
	roles: RolesEnum[],
	alpha?: number
) => {
	const index = rainbowColorNameAtPositionIndex(position, roles)
	return getColor(index, alpha ?? 1)
}

// position to color map
export const rainbowColorNameAtPositionIndex = (
	position: PositionsEnum,
	roles: RolesEnum[]
): number => {
	switch (position) {
		case PositionsEnum.ceo:
		case PositionsEnum.cto:
		case PositionsEnum.cpo:
		case PositionsEnum.first_game_producer:
		case PositionsEnum.second_game_producer:
		case PositionsEnum.associate_producer:
		case PositionsEnum.project_manager:
			return 6

		case PositionsEnum.head_of_development:
		case PositionsEnum.lead_developer:
		case PositionsEnum.technical_art_director:
		case PositionsEnum.lead_three_d_artist:
			return 7

		case PositionsEnum.three_d_generalist:
		case PositionsEnum.three_d_artist:
		case PositionsEnum.three_d_animator:
		case PositionsEnum.concept_artist:
			return roles.includes(RolesEnum.art_mentor) ? 0 : 5

		case PositionsEnum.unity_developer:
			return roles.includes(RolesEnum.mentor) ? 0 : 1

		case PositionsEnum.release_manager:
		case PositionsEnum.qa:
		case PositionsEnum.level_designer:
		case PositionsEnum.economy_designer:
		case PositionsEnum.game_designer:
		case PositionsEnum.sound_designer:
		case PositionsEnum.motion_designer:
			return 3

		case PositionsEnum.hr:
		case PositionsEnum.lead_recruiter:
		case PositionsEnum.recruiter:
		case PositionsEnum.business_development_manager:
		case PositionsEnum.community_manager:
		case PositionsEnum.office_manager:
		case PositionsEnum.secretary:
		case PositionsEnum.assistant_manager:
		case PositionsEnum.masseur:
		case PositionsEnum.cleaner:
			return 2

		default:
			return 4
	}
}

export const rainbowColorNames = (index: number) => {
	const colors = [
		'red',
		'orange',
		'yellow',
		'light-green',
		'green',
		'light-blue',
		'blue',
		'purple'
	]
	return colors[index % colors.length]
}

export const getColor = (index: number, alpha: number, scheme?: ColorSchemeEnum) => {
	const palettes = {
		[ColorSchemeEnum.Rainbow]: [
			'#FF5630',
			'#FF8B00',
			'#FFC400',
			'#36B37E',
			'#00875A',
			'#00B8D9',
			'#0052CC',
			'#5243AA'
		],
		[ColorSchemeEnum.Platform]: [
			'#36B37E',
			'#00B8D9'
		]
	};

	const palette = scheme ? palettes[scheme] : palettes[ColorSchemeEnum.Rainbow];
	const color = palette[index % palette.length];
	const alphaHex = Math.round(alpha * 255).toString(16).padStart(2, '0');
	return color + alphaHex;
};

export const colourStyles = (error?: boolean, scheme: ColorSchemeEnum = ColorSchemeEnum.Rainbow) => {
	return {
		control: (base, state) => ({
			...base,
			borderColor: error ? 'red' : 'hsl(0, 0%, 80%)'
		}),
		singleValue: (styles) => ({
			...styles,
			overflow: 'visible',
			whiteSpace: 'normal',
		}),
		option: (styles, { data, isFocused }) => {
			const payload = data?.payload;
			const index = typeof data?.index === 'number' ? data.index : 0;

			return {
				...styles,
				height: 38,
				textOverflow: 'ellipsis',
				overflow: 'hidden',
				whiteSpace: 'nowrap',
				zIndex: 1000,
				color:
					payload !== 'not_active'
						? getColor(index, 1, scheme)
						: 'rgb(187, 187, 190)',
				backgroundColor:
					isFocused && payload !== 'not_active'
						? getColor(index, 0.1, scheme)
						: 'transparent',
				':active': {
					...styles[':active'],
					backgroundColor: getColor(index, 0.3, scheme)
				},
				pointerEvents: payload === 'not_active' ? 'none' : undefined,
				opacity: payload === 'not_active' ? 0.5 : undefined
			};
		},
		multiValue: (styles, { data }) => {
			const index = typeof data?.index === 'number' ? data.index : 0;
			return {
				...styles,
				backgroundColor: getColor(index, 0.1, scheme)
			};
		},
		multiValueLabel: (styles, { data }) => {
			const index = typeof data?.index === 'number' ? data.index : 0;
			return {
				...styles,
				color: getColor(index, 1, scheme)
			};
		},
		multiValueRemove: (styles, { data }) => {
			const index = typeof data?.index === 'number' ? data.index : 0;
			return {
				...styles,
				color: getColor(index, 1, scheme),
				':hover': {
					backgroundColor: getColor(index, 1, scheme),
					color: 'white'
				}
			};
		}
	};
};