import Papa from 'papaparse'
import * as XLSX from 'xlsx'
import fileDownload from 'js-file-download'

const readFileAsArrayBuffer = (file: File): Promise<ArrayBuffer> => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (e) => {
			if (!e.target?.result) return reject(new Error('No file data'));
			resolve(e.target.result as ArrayBuffer);
		};
		reader.onerror = (err) => reject(err);
		reader.readAsArrayBuffer(file);
	});
};

const isGarbledHeaders = (headers: string[]): boolean => {
	if (!headers || headers.length === 0) return true;

	// Trim headers and check if all are empty
	const allEmpty = headers.every(h => !h.trim());
	if (allEmpty) return true;

	// If any header contains '�', consider it garbled
	return headers.some(h => h.includes('�'));
};

const parseCSVWithEncoding = (file: File, encoding: string): Promise<string[][]> => {
	return new Promise((resolve, reject) => {
		Papa.parse(file, {
			encoding,
			skipEmptyLines: true,
			complete: (results) => {
				if (results.errors && results.errors.length > 0) {
					return reject(results.errors[0]);
				}
				const data = Array.isArray(results.data) ? results.data : [];
				resolve(data as string[][]);
			},
			error: (err) => reject(err),
		});
	});
};

const parseCSV = async (file: File): Promise<string[][]> => {
	// Try UTF-8 first
	let rawData = await parseCSVWithEncoding(file, 'utf-8');
	let headers = (rawData[0] || []).map(h => (h ? h.toString().trim() : ''));

	// If garbled, try Windows-1251
	if (isGarbledHeaders(headers)) {
		rawData = await parseCSVWithEncoding(file, 'windows-1251');
	}

	return rawData;
};

const parseXLSX = async (file: File): Promise<string[][]> => {
	const data = await readFileAsArrayBuffer(file);
	const workbook = XLSX.read(new Uint8Array(data), { type: 'array' });
	const firstSheetName = workbook.SheetNames[0];
	const ws = workbook.Sheets[firstSheetName];
	const sheetData = XLSX.utils.sheet_to_json(ws, { header: 1 }) as any[][];
	return sheetData.filter(row => Array.isArray(row) && row.some(cell => cell && cell.toString().trim() !== ''));
};

export interface ParsedFileResult {
	headers: string[];
	data: string[][];
}

export const parseFile = async (file: File): Promise<ParsedFileResult> => {
	const ext = file.name.split('.').pop()?.toLowerCase();
	let rawData: string[][];

	if (ext === 'csv') {
		rawData = await parseCSV(file);
	} else if (ext === 'xlsx' || ext === 'xls') {
		rawData = await parseXLSX(file);
	} else {
		throw new Error('Unsupported file type. Please upload a CSV, XLS, or XLSX file.');
	}

	const headers = (rawData[0] || []).map(h => (h ? h.toString().trim() : ''));
	const data = rawData.slice(1);
	return { headers, data };
};


export function downloadBase64File(base64Data: string, fileName: string) {
	const binaryData = atob(base64Data)
	const arrayBuffer = new ArrayBuffer(binaryData.length)
	const uint8Array = new Uint8Array(arrayBuffer)

	for (let i = 0; i < binaryData.length; i++) {
		uint8Array[i] = binaryData.charCodeAt(i)
	}

	const blob = new Blob([uint8Array], {
		type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
	})

	const url = URL.createObjectURL(blob)

	const link = document.createElement('a')
	link.href = url
	link.download = fileName

	link.click()

	URL.revokeObjectURL(url)
}

export async function downloadAndTriggerFile(fileResponse: any,) {
	try {
		// Convert from buffer object to a raw Blob
		const uint8Array = new Uint8Array(fileResponse.blob.data);
		const blob = new Blob([uint8Array], { type: 'application/octet-stream' });

		// Trigger file download using fileDownload
		fileDownload(blob, fileResponse.name);
	} catch (error) {
		console.error('Error during file download:', error);
	}
}

export function downloadBlobFile(blob: Blob, fileName: string): void {
	const url = URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
}