import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import {
	BalanceCalculationResponse,
	CreateOperationStatementDto,
	FilterOperationStatementDto,
	OperationStatement,
	UpdateOperationStatementDto
} from '../../types/finance/operationStatement'
import { CreateCashboxExpenseDto, FilterCashboxExpenseDto } from '../../types/finance/cashboxExpense'
import { Invoice } from '../../types/finance/invoice'

export const operationStatementsApi = createApi({
	reducerPath: 'operationStatementsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `finance`,
	}),
	tagTypes: ['OperationStatements', 'OperationStatementsFile'],
	endpoints: (builder) => ({
		createOperationStatement: builder.mutation<OperationStatement, CreateOperationStatementDto>({
			query: (body) => ({
				url: `/operation-statement`,
				method: 'POST',
				data: body,
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		updateOperationStatement: builder.mutation<
			OperationStatement,
			{ id: string; changes: UpdateOperationStatementDto }
		>({
			query: ({ id, changes }) => ({
				url: `/operation-statement/${id}`,
				method: 'PATCH',
				data: changes,
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		executeOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/execute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		undoExecuteOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/undo-execute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		deleteOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		getAllExecutedOperationStatements: builder.query<OperationStatement[], void>
		({
			query: () => ({
				url: `/operation-statement/allExecuted`,
				method: 'GET',
			}),
			providesTags: ['OperationStatements'],
		}),

		getOperationStatements: builder.query<
			{ data: OperationStatement[]; total: number },
			FilterOperationStatementDto
		>({
			query: (params) => ({
				url: `/operation-statements`,
				method: 'GET',
				params,
			}),
			providesTags: ['OperationStatements'],
		}),

		getOperationStatementsDocumentNumbers: builder.query<string[], void>({
			query: () => ({
				url: `/operation-numbers`,
				method: 'GET',
			}),
			providesTags: ['OperationStatements'],
		}),

		importOperationStatementsFile: builder.mutation<
			{ fileId: string; fileName: string },
			{ file: File; operations: CreateOperationStatementDto[] }
		>({
			query: ({ file, operations }) => {
				const formData = new FormData();
				formData.append('file', file);
				formData.append('operations', JSON.stringify(operations));

				return {
					url: '/process-file',
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
			invalidatesTags: ['OperationStatementsFile', 'OperationStatements'],
		}),
		getBalanceCalculation: builder.query<
			BalanceCalculationResponse,
			{ startDate?: string; endDate?: string, useArchived?: boolean }
		>({
			query: ({ startDate, endDate, useArchived }) => ({
				url: `/balance-calculation`,
				method: 'GET',
				params: { startDate, endDate, useArchived },
			}),
			providesTags: ['OperationStatements'],
		}),

		deleteOperationStatementFile: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/file`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OperationStatements', 'OperationStatementsFile'],
		}),

		addOperationStatementFile: builder.mutation<
			{ fileId: string; fileName: string },
			{ id: string; file: File }
		>({
			query: ({ id, file }) => {
				const formData = new FormData();
				formData.append('file', file);

				return {
					url: `/operation-statement/${id}/file`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
			invalidatesTags: ['OperationStatements', 'OperationStatementsFile'],
		}),

		downloadOperationStatementFile: builder.mutation<any, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/file`,
				method: 'GET',
			}),
		}),

		getAllCashboxExpenses: builder.query<{ data: OperationStatement[]; total: number }, FilterCashboxExpenseDto>({
			query: (params) => ({
				url: `/cashboxExpenses/`,
				method: 'GET',
				params,
			}),
			providesTags: ['OperationStatements'],
		}),
		createCashboxExpense: builder.mutation<{
			operationStatement: OperationStatement;
			invoice: Invoice;
		}, { createDto: CreateCashboxExpenseDto; file?: File }>({
			query: ({ createDto, file }) => {
				const formData = new FormData();
				formData.append('createDto', JSON.stringify(createDto));
				if (file) {
					formData.append('file', file);
				}
				return {
					url: `/cashboxExpenses/`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				};
			},
			invalidatesTags: ['OperationStatements'],
		}),
		updateCashboxExpense: builder.mutation<
			{ operationStatement: OperationStatement, invoice: Invoice },
			{ id: string; changes: UpdateOperationStatementDto }
		>({
			query: ({ id, changes }) => ({
				url: `/cashboxExpenses/${id}`,
				method: 'PATCH',
				data: changes,
			}),
			invalidatesTags: ['OperationStatements'],
		}),
		deleteCashboxExpense: builder.mutation<void, string>({
			query: ( id) => ({
				url: `/cashboxExpenses/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OperationStatements'],
		}),
	}),
});

export const {
	useCreateOperationStatementMutation,
	useUpdateOperationStatementMutation,
	useExecuteOperationStatementMutation,
	useUndoExecuteOperationStatementMutation,
	useDeleteOperationStatementMutation,
	useGetOperationStatementsQuery,
	useGetAllExecutedOperationStatementsQuery,
	useGetOperationStatementsDocumentNumbersQuery,
	useImportOperationStatementsFileMutation,
	useGetBalanceCalculationQuery,
	useDeleteOperationStatementFileMutation,
	useAddOperationStatementFileMutation,
	useDownloadOperationStatementFileMutation,

	useGetAllCashboxExpensesQuery,
	useCreateCashboxExpenseMutation,
	useUpdateCashboxExpenseMutation,
	useDeleteCashboxExpenseMutation,
} = operationStatementsApi;
