import { Col, Row } from 'react-bootstrap'
import React from 'react'
import ContractorsTable from './Tables/ContractorsTable'
import CreateContractorTypeModal from './Modals/CreateContractorTypeModal'
import ContractorTypesTable from './Tables/ContractorTypesTable'
import CreateContractorClassModal from './Modals/CreateContractorClassModal'
import ContractorClassTable from './Tables/ContractorClassTable'

function Contractors() {
	return (
		<>
			<ContractorsTable/>
			<Row className="mb-2">
				<Col xs={12} md={6}>
					<Row className="my-4">
						<Col xs="auto" className="ml-auto text-right mt-n1">
							<CreateContractorTypeModal />
						</Col>
					</Row>
					<ContractorTypesTable/>
				</Col>

				<Col xs={12} md={6}>
					<Row className="my-4">
						<Col xs="auto" className="ml-auto text-right mt-n1">
							<CreateContractorClassModal />
						</Col>
					</Row>
					<ContractorClassTable/>
				</Col>
			</Row>
		</>
	)
}

export default Contractors