import { useTranslation } from 'react-i18next'
import { Col, Container, Nav, Row, Tab } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import React from 'react'
import TurnoversReport from './Tables/TurnoversReport'
import NetSettlementReport from './Tables/NetSettlementReport'
import CashFlowReport from './Tables/CashFlowReport'

function ReportsContainer({children}) {
	const { t } = useTranslation();

	return (
		<Container fluid className="p-0">
			<Row className="mb-2 mb-xxl-4">
				<Col xs="auto" className="d-none d-sm-block">
					<h3>{t('Reports_header')}</h3>
				</Col>
			</Row>
			<div className={'tab'}>
				<Tab.Container
					id="left-tabs-example"
				>
					<Nav variant="tabs">
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/reports/turnover-report"
							>
								{t('Reports_turnoversReport')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/reports/net-settlement"
							>
								{t('Reports_netSettlement')}
							</Nav.Link>
						</Nav.Item>
						<Nav.Item>
							<Nav.Link
								as={NavLink}
								to="/finances/reports/cash-flow"
							>
								{t('Reports_cashFlow')}
							</Nav.Link>
						</Nav.Item>
					</Nav>
					<Tab.Content>
						{children}
					</Tab.Content>
				</Tab.Container>
			</div>
		</Container>
	);
}

export function TurnoversReportTab() {
	return (
		<ReportsContainer>
			<TurnoversReport/>
		</ReportsContainer>
	)
}

export function NetSettlementReportTab() {
	return (
		<ReportsContainer>
			<NetSettlementReport/>
		</ReportsContainer>
	)
}

export function CashFlowReportTab() {
	return (
		<ReportsContainer>
			<CashFlowReport/>
		</ReportsContainer>
	)
}