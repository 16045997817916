import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup, SingleselectFormGroup } from 'components/FormGroups';
import ModalView from 'components/Modals/ModalView';
import { FaBuilding, FaDollarSign, FaPlus, FaWallet } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';
import {
	useAddAccountMutation,
	useLoadAccountTypesQuery,
	useLoadCurrenciesQuery,
	useLoadOrganizationsQuery
} from '../../../../../redux/slices/financeDictionaries';
import SelectOption from '../../../../../types/selectOption';
import { RolesEnum } from '../../../../../types/roles';

const CreateAccountModal = () => {
	const { t } = useTranslation();

	const [name, setName] = useState('');
	const [beneficiaryName, setBeneficiaryName] = useState('');
	const [IBAN, setIBAN] = useState('');
	const [bankName, setBankName] = useState('');
	const [swiftCode, setSwiftCode] = useState('');
	// const [sum, setSum] = useState(0);
	const [organization, setOrganization] = useState<SelectOption>();
	const [currency, setCurrency] = useState<SelectOption>();
	const [type, setType] = useState<SelectOption>();
	const [nameError, setNameError] = useState(false);
	const [organizationError, setOrganizationError] = useState(false);
	const [currencyError, setCurrencyError] = useState(false);
	const [typeError, setTypeError] = useState(false);

	const { data: organizations = [] } = useLoadOrganizationsQuery();
	const { data: currencies = [] } = useLoadCurrenciesQuery();
	const { data: accountTypes = [] } = useLoadAccountTypesQuery();
	const [addAccount] = useAddAccountMutation();

	const handleCreateAccount = async () => {
		let isValid = true;

		// Set error flags based on validation
		setNameError(!name);
		setOrganizationError(!organization);
		setCurrencyError(!currency);
		setTypeError(!type);

		if (!name || !organization || !currency || !type) {
			isValid = false;
		}

		if (!isValid) return false;

		const selectedOrganization = organizations.find(org => org._id === organization?.value);
		const selectedCurrency = currencies.find(curr => curr._id === currency?.value);
		const selectedAccountType = accountTypes.find(accType => accType._id === type?.value);

		if (!selectedOrganization || !selectedCurrency || !selectedAccountType) {
			console.error("Invalid selections. Please select valid organization, currency, and account type.");
			setOrganizationError(!selectedOrganization);
			setCurrencyError(!selectedCurrency);
			setTypeError(!selectedAccountType);
			return false;
		}

		const newAccount = {
			name,
			organization: selectedOrganization._id,
			currency: selectedCurrency._id,
			type: selectedAccountType._id,
			beneficiaryName,
			IBAN,
			bankName,
			swiftCode,
			// sum,
		};

		try {
			await addAccount(newAccount);
			clearForm();
			return true;
		} catch (error) {
			console.error("Error creating account:", error);
			return false;
		}
	};

	const clearForm = () => {
		setName('');
		setBeneficiaryName('');
		setIBAN('');
		setBankName('');
		setSwiftCode('');
		// setSum(0);
		setOrganization(undefined);
		setCurrency(undefined);
		setType(undefined);
		setNameError(false);
		setOrganizationError(false);
		setCurrencyError(false);
		setTypeError(false);
	};

	return (
		<ModalView
			title={'CreateAccountModal_title'}
			tooltipText={'CreateAccountModal_tooltipText'}
			id="CreateNewAccount"
			buttonIcon={FaPlus}
			onAccept={handleCreateAccount}
			clearFunction={clearForm}
			acceptButtonText={'CreateAccountModal_confirmButton'}
			rejectButtonText={'CreateAccountModal_cancelButton'}
			buttonText={'CreateAccountModal_createButton'}
			requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('CreateAccountModal_accountName')}
						value={name}
						onChange={(e) => {
							setName(e.target.value);
							setNameError(false);
						}}
						error={nameError}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('CreateAccountModal_organization')}
						options={organizations.map((org, index) => ({ label: org.name, value: org._id, index }))}
						value={organization}
						onChange={(selected) => {
							setOrganization(selected);
							setOrganizationError(false);
						}}
						error={organizationError}
						prefix={<FaBuilding className="w-100 h-100" />}
					/>
				</Col>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('CreateAccountModal_accountType')}
						options={accountTypes.map((type, index) => ({ label: t(type.name), value: type._id, index }))}
						value={type}
						onChange={(selected) => {
							setType(selected);
							setTypeError(false);
						}}
						error={typeError}
						prefix={<FaWallet className="w-100 h-100" />}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={6}>
					<SingleselectFormGroup
						label={t('CreateAccountModal_currency')}
						options={currencies.map((curr, index) => ({ label: curr.name, value: curr._id, index }))}
						value={currency}
						onChange={(selected) => {
							setCurrency(selected);
							setCurrencyError(false);
						}}
						error={currencyError}
						prefix={<FaDollarSign className="w-100 h-100" />}
					/>
				</Col>
			</Row>

			{/* Beneficiary Information */}
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="beneficiaryName"
						label={t('CreateAccountModal_beneficiaryName')}
						value={beneficiaryName}
						onChange={(e) => setBeneficiaryName(e.target.value)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="IBAN"
						label={t('CreateAccountModal_IBAN')}
						value={IBAN}
						onChange={(e) => setIBAN(e.target.value)}
					/>
				</Col>
			</Row>

			{/* Bank Information */}
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="bankName"
						label={t('CreateAccountModal_bankName')}
						value={bankName}
						onChange={(e) => setBankName(e.target.value)}
					/>
				</Col>
			</Row>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="swiftCode"
						label={t('CreateAccountModal_swiftCode')}
						value={swiftCode}
						onChange={(e) => setSwiftCode(e.target.value)}
					/>
				</Col>
			</Row>

			{/*/!* Sum *!/*/}
			{/*<InputFormGroup*/}
			{/*	name="sum"*/}
			{/*	label={t('CreateAccountModal_sum')}*/}
			{/*	type="number"*/}
			{/*	value={sum}*/}
			{/*	onChange={(e) => setSum(Number(e.target.value))}*/}
			{/*/>*/}
		</ModalView>
	);
};

export default CreateAccountModal;
