import React, { useState, useMemo } from 'react';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import RangeCalendar from '../../../../../components/RangeCalendar';
import { MultiselectFormGroup, SingleselectFormGroup } from '../../../../../components/FormGroups';
import { formatNumber } from '../../../../../utils/number';
import { currencySign } from '../../../../../types/finance/currency';
import { ReportNode, ReportResponse } from '../../../../../types/finance/reports';

import {
	useGetUnarchivedContractorClassesQuery, useGetUnarchivedContractorTypesQuery,
	useLoadContractorsSelectQuery,
	useLoadOrganizationsSelectQuery
} from '../../../../../redux/slices/financeDictionaries'
import { useGetNetSettlementQuery } from '../../../../../redux/slices/reports';
import Loader from '../../../../../components/Loader';
import NetSettlementExcelButton from './Buttons/NetSettlementExcelButton'
import TableError from '../../../../../components/TableError'

const MANAGEMENT_CURRENCY_NAME = 'USD';
const NATIVE_CURRENCY_NAME = 'UAH';

/**
 * RowItem renders a single node (and recursively its children).
 * It uses the node.name directly (which should already be localized)
 * and indents the row based on its level.
 */
function RowItem({
					 node,
					 level,
					 showNative
				 }: {
	node: ReportNode;
	level: number;
	showNative: boolean;
}) {
	const [isOpen, setIsOpen] = useState(false);
	const hasChildren = node.children.length > 0;
	const isLeaf = !hasChildren;

	const indentStyle = {
		paddingLeft: `${20 * level}px`,
		width: '500px',
		minWidth: '500px',
		maxWidth: '500px',
	};

	const openingRawVal = node.openingRaw || 0;
	const incomingRawVal = node.incomingRaw || 0;
	const outgoingRawVal = node.outgoingRaw || 0;
	const closingRawVal = node.closingRaw || 0;

	const openingNativeVal = node.openingNative;
	const incomingNativeVal = node.incomingNative;
	const outgoingNativeVal = node.outgoingNative;
	const closingNativeVal = node.closingNative;

	// Colors (for top-level row and for the closing cells).
	const bgColor = `rgba(157,203,255, 0.5)`;
	const closingBgColor = `rgba(255, 181, 72, 0.6)`;

	function renderGroup(
		openVal: number,
		incVal: number,
		outVal: number,
		closVal: number,
		currency: string | undefined,
		isLeaf: boolean
	) {
		// Format the numbers (even zero will be rendered here).
		const formattedOpen = formatNumber(openVal);
		const formattedInc = formatNumber(incVal);
		const formattedOut = formatNumber(outVal);
		const formattedClos = formatNumber(closVal);

		// By default, display the value and the currency sign (if provided).
		let displayOpening = `${formattedOpen} ${currency ? currencySign(currency) : ''}`;
		let displayIncoming = `${formattedInc} ${currency ? currencySign(currency) : ''}`;
		let displayOutgoing = `${formattedOut} ${currency ? currencySign(currency) : ''}`;
		let displayClosing = `${formattedClos} ${currency ? currencySign(currency) : ''}`;

		// Apply leaf node rules.
		if (isLeaf) {
			// Rule 1: Only opening cell displayed if incoming and outgoing are zero.
			if (incVal === 0 && outVal === 0) {
				displayIncoming = '';
				displayOutgoing = '';
				displayClosing = '';
			}
				// Rule 2: Only incoming and outgoing cells displayed if opening is zero
			// and at least one of incoming/outgoing is nonzero.
			else if (openVal === 0 && (incVal !== 0 || outVal !== 0)) {
				displayOpening = '';
				displayClosing = '';
			}
		}

		return (
			<>
				<td className="text-right text-nowrap align-middle">{displayOpening}</td>
				<td className="text-right text-nowrap align-middle">{displayIncoming}</td>
				<td className="text-right text-nowrap align-middle">{displayOutgoing}</td>
				<td
					className="text-right text-nowrap align-middle"
					style={{ backgroundColor: closingBgColor }}
				>
					{displayClosing}
				</td>
			</>
		);
	}

	return (
		<>
			<tr
				className={hasChildren ? 'cursor-pointer' : ''}
				style={{
					...(level === 0 ? { backgroundColor: bgColor } : {}),
					...(node.isArchived ? { backgroundColor: '#d3d3d3' } : {}),
				}}
				onClick={() => hasChildren && setIsOpen(!isOpen)}
			>
				<td className="text-break text-wrap align-middle" style={indentStyle}>
					{hasChildren && (
						<FaChevronRight
							size={12}
							className="me-1 mx-1"
							style={{
								transition: 'transform 0.2s',
								transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
							}}
						/>
					)}
					{node.name}
				</td>

				{showNative && (
					<>
						{renderGroup(
							isLeaf ? openingRawVal : openingNativeVal,
							isLeaf ? incomingRawVal : incomingNativeVal,
							isLeaf ? outgoingRawVal : outgoingNativeVal,
							isLeaf ? closingRawVal : closingNativeVal,
							isLeaf ? node.currency : NATIVE_CURRENCY_NAME,
							isLeaf
						)}
					</>
				)}

				{renderGroup(
					node.openingMgm,
					node.incomingMgm,
					node.outgoingMgm,
					node.closingMgm,
					MANAGEMENT_CURRENCY_NAME,
					isLeaf
				)}
			</tr>

			{isOpen &&
				node.children.map(child => (
					<RowItem
						key={child.id}
						node={child}
						level={level + 1}
						showNative={showNative}
					/>
				))}
		</>
	);
}

/**
 * NetSettlementTable renders the table header, report rows (via RowItem)
 * and the totals row.
 * It displays the localized name (from node.name) in the first column.
 */
function NetSettlementTable({
								tree,
								totals,
								showNative,
							}: {
	tree: ReportNode[];
	totals: ReportResponse['totals'];
	showNative: boolean;
}) {
	const { t } = useTranslation();

	return (
		<Table bordered hover responsive className="table-striped table-sm mt-3">
			<thead>
			<tr>
				<th
					rowSpan={2}
					className="align-middle text-break text-wrap"
					style={{
						width: '500px',
						minWidth: '500px',
						maxWidth: '500px',
					}}
				>
					{t('type')}
				</th>

				{showNative && (
					<th colSpan={4} className="text-center">
						{t('systemNative')}
					</th>
				)}

				<th colSpan={4} className="text-center">
					{t('managementCurrency')}
				</th>
			</tr>
			<tr>
				{showNative && (
					<>
						<th className="text-center">{t('openingBalance')}</th>
						<th className="text-center">{t('incoming')}</th>
						<th className="text-center">{t('outcoming')}</th>
						<th className="text-center">{t('closingBalance')}</th>
					</>
				)}
				<th className="text-center">{t('openingBalance')}</th>
				<th className="text-center">{t('incoming')}</th>
				<th className="text-center">{t('outcoming')}</th>
				<th className="text-center">{t('closingBalance')}</th>
			</tr>
			</thead>

			<tbody>
			{tree.map(node => (
				<RowItem key={node.id} node={node} level={0} showNative={showNative} />
			))}

			<tr className="fw-bold bg-warning">
				<td>{t('total')}</td>

				{showNative && (
					<>
						<td className="text-right text-nowrap">
							{formatNumber(totals.openingNativeConv)} {currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.incomingNativeConv)} {currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.outgoingNativeConv)} {currencySign(NATIVE_CURRENCY_NAME)}
						</td>
						<td className="text-right text-nowrap">
							{formatNumber(totals.closingNativeConv)} {currencySign(NATIVE_CURRENCY_NAME)}
						</td>
					</>
				)}

				<td className="text-right text-nowrap">
					{formatNumber(totals.openingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.incomingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.outgoingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
				<td className="text-right text-nowrap">
					{formatNumber(totals.closingMgm)} {currencySign(MANAGEMENT_CURRENCY_NAME)}
				</td>
			</tr>
			</tbody>
		</Table>
	);
}

/**
 * NetSettlementReport is the main container.
 * It provides a date range filter, organization, contractor, contractor class and contractor type filters,
 * and toggles between full (native columns visible) and short reports.
 *
 * The report request sends the localized names so that node.name is already set correctly.
 */
export default function NetSettlementReport() {
	const { t, i18n } = useTranslation();

	// Load dictionaries for filtering.
	const { data: contractors = [] } = useLoadContractorsSelectQuery();
	const { data: organizations = [] } = useLoadOrganizationsSelectQuery();
	const { data: contractorClasses = [] } = useGetUnarchivedContractorClassesQuery();
	const { data: contractorTypes = [] } = useGetUnarchivedContractorTypesQuery();

	// State for additional filters.
	const [selectedContractorClasses, setSelectedContractorClasses] = useState<string[]>([]);
	const [selectedContractorTypes, setSelectedContractorTypes] = useState<string[]>([]);

	const [documentDateFrom, setDocumentDateFrom] = useState<Date>(moment().startOf('year').toDate());
	const [documentDateTo, setDocumentDateTo] = useState<Date>(moment().endOf('year').toDate());

	const [showNative, setShowNative] = useState(true);
	const [useArchived, setUseArchived] = useState(false)

	const [selectedOrganizations, setSelectedOrganizations] = useState<string[]>([]);
	const [selectedContractors, setSelectedContractors] = useState<string[]>([]);

	const ranges = [
		{ value: '1d', label: 'for_one_day', index: 0 },
		{ value: '1w', label: 'for_one_week', index: 1 },
		{ value: '1m', label: 'for_one_month', index: 2 },
		{ value: '1y', label: 'for_one_year', index: 3 },
		{ value: 'hy', label: 'for_half_year', index: 4 },
		{ value: 'at', label: 'for_all_time', index: 5 },
	];

	const handleQuickSelect = (option: { value: string; label: string }) => {
		let newFrom: Date;
		let newTo: Date;

		switch (option.value) {
			case '1d':
				newFrom = moment().startOf('day').toDate();
				newTo = moment().endOf('day').toDate();
				break;
			case '1w':
				newFrom = moment().startOf('week').toDate();
				newTo = moment().endOf('week').toDate();
				break;
			case '1m':
				newFrom = moment().startOf('month').toDate();
				newTo = moment().endOf('month').toDate();
				break;
			case '1y':
				newFrom = moment().startOf('year').toDate();
				newTo = moment().endOf('year').toDate();
				break;
			case 'hy':
				newFrom = moment().startOf('year').add(6, 'months').toDate();
				newTo = moment().endOf('year').toDate();
				break;
			case 'at':
				newFrom = moment('01.01.2022', 'DD.MM.YYYY').toDate();
				newTo = moment().endOf('year').toDate();
				break;
			default:
				newFrom = moment().startOf('year').toDate();
				newTo = moment().endOf('year').toDate();
				break;
		}

		setDocumentDateFrom(newFrom);
		setDocumentDateTo(newTo);
	};

	const validFrom = documentDateFrom || moment().startOf('year').toDate();
	const validTo = documentDateTo || moment().endOf('year').toDate();

	// Build filters for the net settlement report.
	// The backend will use i18n.language to send back localized names.
	const filters = useMemo(() => {
		return {
			startDate: validFrom.toISOString(),
			endDate: validTo.toISOString(),
			organizations: selectedOrganizations.length ? selectedOrganizations : undefined,
			contractors: selectedContractors.length ? selectedContractors : undefined,
			contractorClasses: selectedContractorClasses.length ? selectedContractorClasses : undefined,
			contractorTypes: selectedContractorTypes.length ? selectedContractorTypes : undefined,
			language: i18n.language as 'en' | 'uk' | undefined,
			useArchived
		};
	}, [
		validFrom,
		validTo,
		documentDateFrom,
		documentDateTo,
		selectedOrganizations,
		selectedContractors,
		selectedContractorClasses,
		selectedContractorTypes,
		i18n.language,
		useArchived
	]);

	// Call the net settlement report query.
	const { data: reportData, isLoading, isFetching, isError } = useGetNetSettlementQuery(filters);

	const finalData: ReportResponse = reportData || {
		tree: [],
		totals: {
			openingNativeConv: 0,
			incomingNativeConv: 0,
			outgoingNativeConv: 0,
			closingNativeConv: 0,
			openingMgm: 0,
			incomingMgm: 0,
			outgoingMgm: 0,
			closingMgm: 0,
		},
	};

	return (
		<>
			<Row xs={1} sm={1} md={2} lg={2} xxl={4}>
				<Col className='mb-3 mb-md-0'>
					<RangeCalendar
						startDate={documentDateFrom}
						endDate={documentDateTo}
						withSelect
						withFullWidth
						onChange={([start, end]) => {
							setDocumentDateFrom(start!);
							setDocumentDateTo(end!);
						}}
					/>
				</Col>

				<Col>
					<SingleselectFormGroup
						placeholder=""
						options={ranges.map(r => ({ ...r, label: t(r.label) }))}
						defaultValue={{ ...ranges[3], label: t(ranges[3].label) }}
						onChange={val => val && handleQuickSelect(val)}
						isClearable={false}
						error={false}
					/>
				</Col>

				<Col md={12} lg={12} xxl={6} className='text-right'>
					<NetSettlementExcelButton
						data={reportData}
						showNative={showNative}
						dateFrom={documentDateFrom}
						dateTo={documentDateTo}
					/>
				</Col>
			</Row>

			<Row xs={1} sm={1} md={2} lg={2} xxl={4} className="mt-3">
				<Col>
					<MultiselectFormGroup
						placeholder={t('organizations')}
						options={organizations.map((org: any, index: number) => ({
							value: org._id,
							label: org.name,
							index,
						}))}
						onChange={selected => setSelectedOrganizations(selected.map(s => s.value))}
					/>
				</Col>

				<Col>
					<MultiselectFormGroup
						placeholder={t('contractors')}
						options={contractors.map((ctr: any, index: number) => ({
							value: ctr._id,
							label: ctr.name,
							index,
						}))}
						onChange={selected => setSelectedContractors(selected.map(s => s.value))}
					/>
				</Col>
				<Col>
					<MultiselectFormGroup
						placeholder={t('contractor_class')}
						options={contractorClasses.map((cls: any, index: number) => ({
							value: cls._id,
							label: i18n.language === 'uk' ? cls.ukName : cls.enName,
							index,
						}))}
						onChange={selected => setSelectedContractorClasses(selected.map(s => s.value))}
					/>
				</Col>

				<Col>
					<MultiselectFormGroup
						placeholder={t('contractor_type')}
						options={contractorTypes.map((type: any, index: number) => ({
							value: type._id,
							label: i18n.language === 'uk' ? type.ukName : type.enName,
							index,
						}))}
						onChange={selected => setSelectedContractorTypes(selected.map(s => s.value))}
					/>
				</Col>
			</Row>

			<Row xs={1} sm={1} md={1} lg={1} xxl={2}>
				<Col className="d-flex flex-wrap align-items-center justify-content-start px-3 py-2">
					<span>{`${t('filter')}:`}</span>
					<Button
						variant={useArchived ? 'outline-primary' : 'primary'}
						onClick={() => setUseArchived(false)}
						className="my-1 mx-2 shadow-none"
					>
						{t('no_use_archived')}
					</Button>
					<Button
						variant={useArchived ? 'primary' : 'outline-primary'}
						onClick={() => setUseArchived(true)}
						className="my-1 shadow-none"
					>
						{t('use_archived')}
					</Button>
				</Col>

				<Col className="d-flex flex-wrap align-items-center justify-content-start justify-content-xxl-end px-3 py-2">
					<span>{`${t('display_with')}:`}</span>
					<Button
						variant={showNative ? 'primary' : 'outline-primary'}
						onClick={() => setShowNative(prev => !prev)}
						className="my-1 mx-2 shadow-none"
					>
						{t('full_report')}
					</Button>
					<Button
						variant={showNative ? 'outline-primary' : 'primary'}
						onClick={() => setShowNative(prev => !prev)}
						className="my-1 shadow-none"
					>
						{t('short_report')}
					</Button>
				</Col>
			</Row>

			{isLoading || isFetching ? (
				<Loader />
			) : isError ? (
				<TableError>{t('request_error')}</TableError>
			) : finalData.tree.length > 0 ? (
				<NetSettlementTable tree={finalData.tree} totals={finalData.totals} showNative={showNative} />
			) : (
				<TableError>{t('no_data')}</TableError>
			)}
		</>
	);
}
