import { createApi } from '@reduxjs/toolkit/query/react';
import {
	AddNewPaymentForEmployee,
	AddSalaryChanges, CheckSalaryData, DeletePaymentForEmployee,
	RemoveSalaryChanges,
	SalariesTable, SalaryTableDollarRateData, SalaryTableWithdrawData,
	UpdateSalaryPaymentType, UpdateSalaryTableStatusData
} from '../../types/salary'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'

export const salariesTableApi = createApi({
	reducerPath: 'salariesTableApi',
	baseQuery: axiosBaseQuery({
		baseUrl: 'finance',
	}),
	tagTypes: ['SalariesTable'],
	endpoints: (builder) => ({
		getSalariesTableByMonth: builder.query<SalariesTable, string>({
			query: (month) => ({
				url: `/${month}/salaries_table`,
				method: 'GET',
			}),
			providesTags: ['SalariesTable'],
		}),
		addNewPaymentForEmployee: builder.mutation<SalariesTable, AddNewPaymentForEmployee>({
			query: (updatingData) => ({
				url: '/add_new_payment',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		deletePaymentForEmployee: builder.mutation<SalariesTable, DeletePaymentForEmployee>({
			query: (deletingData) => ({
				url: '/delete_payment',
				method: 'DELETE',
				data: deletingData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		addBonusesToSalariesTable: builder.mutation<SalariesTable, AddSalaryChanges>({
			query: (creationData) => ({
				url: '/add_bonuses',
				method: 'PUT',
				data: creationData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		addFinesToSalariesTable: builder.mutation<SalariesTable, AddSalaryChanges>({
			query: (creationData) => ({
				url: '/add_fines',
				method: 'PUT',
				data: creationData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		removeBonusFromSalaryTable: builder.mutation<SalariesTable, RemoveSalaryChanges>({
			query: (removeData) => ({
				url: '/remove_bonus',
				method: 'DELETE',
				data: removeData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		removeFineFromSalariesTable: builder.mutation<SalariesTable, RemoveSalaryChanges>({
			query: (removeData) => ({
				url: '/remove_fine',
				method: 'DELETE',
				data: removeData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		updateSalaryPaymentType: builder.mutation<SalariesTable, UpdateSalaryPaymentType>({
			query: (updatingData) => ({
				url: '/update_payment_type',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		checkEmployeeSalary: builder.mutation<SalariesTable, CheckSalaryData>({
			query: (checkData) => ({
				url: '/check_salary',
				method: 'PUT',
				data: checkData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		updateSalaryTableStatus: builder.mutation<SalariesTable, UpdateSalaryTableStatusData>({
			query: (updatedData) => ({
				url: '/update_status',
				method: 'PUT',
				data: updatedData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		setSalariesTableDollarRate: builder.mutation<SalariesTable, SalaryTableDollarRateData>({
			query: (updatingData) => ({
				url: '/set_dollar_rate',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
		withdrawSalariesTable: builder.mutation<SalariesTable, SalaryTableWithdrawData>({
			query: (withdrawData) => ({
				url: '/withdraw_salary',
				method: 'PUT',
				data: withdrawData,
			}),
			invalidatesTags: ['SalariesTable'],
		}),
	}),
});

export const {
	useGetSalariesTableByMonthQuery,
	useAddNewPaymentForEmployeeMutation,
	useDeletePaymentForEmployeeMutation,
	useAddBonusesToSalariesTableMutation,
	useAddFinesToSalariesTableMutation,
	useRemoveBonusFromSalaryTableMutation,
	useRemoveFineFromSalariesTableMutation,
	useUpdateSalaryPaymentTypeMutation,
	useCheckEmployeeSalaryMutation,
	useUpdateSalaryTableStatusMutation,
	useSetSalariesTableDollarRateMutation,
	useWithdrawSalariesTableMutation,
} = salariesTableApi;
