import { IconType } from 'react-icons'
import { RolesEnum } from '../../types/roles'
import ModalView from './ModalView'

interface UpdateModalProps {
	id: string
	title: string
	onAccept: () => void
	icon: IconType
	requiredRoles?: RolesEnum[]
	tooltipText? : string
	clearFunction?: () => void
	children: any
	noLeftMargin?: boolean
	size?: string;
	acceptButtonText?: string;
}

export default function UpdateModal({
										id,
										title,
										onAccept,
										icon,
										requiredRoles,
										tooltipText,
										children,
										clearFunction,
										size,
										acceptButtonText
}: UpdateModalProps) {
	return (
		<ModalView
			id={`UpdateModal_${title}_${id}`}
			title={title}
			tooltipText={tooltipText || 'edit'}
			buttonIcon={icon}
			buttonText=""
			numberButtonSize={16}
			tooltipPlacement='top'
			buttonOutline
			className={'rounded-lg'}
			rejectButtonText={'cancelButton'}
			buttonSmall
			size={size as "sm" | "lg" | "xl" | "xxl" | undefined}
			onAccept={onAccept}
			clearFunction={clearFunction}
			acceptButtonText={acceptButtonText || 'update'}
			requiredRoles={requiredRoles}
		>
			{children}
		</ModalView>
	)
}