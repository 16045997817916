import React from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/Tables/UniversalTable';

import { FaArchive } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import TableError from '../../../../../components/TableError'
import { RolesEnum } from '../../../../../types/roles'
import DangerModal from '../../../../../components/Modals/DangerModal'
import SuccessModal from '../../../../../components/Modals/SuccessModal'
import {
	useArchiveInvoiceClassMutation,
	useGetInvoiceClassesQuery,
	useUnarchiveInvoiceClassMutation
} from '../../../../../redux/slices/invoices'
import { InvoiceClass } from '../../../../../types/finance/invoice'
import UpdateInvoiceClassModal from '../../Invoices/Modals/UpdateInvoiceClassModal'
import Loader from '../../../../../components/Loader'
import { Col, Row } from 'react-bootstrap'
import CreateInvoiceClassModal from '../../Invoices/Modals/CreateInvoiceClassModal'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface InvoiceClassRowProps {
	invoiceClass: InvoiceClass;
	handlers: any;
	index: number;
}

function InvoiceClassRow({ invoiceClass, handlers, index }: InvoiceClassRowProps) {
	const { i18n } = useTranslation();

	const [archiveInvoiceClass, unarchiveInvoiceClass] = handlers

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? invoiceClass.ukName : invoiceClass.enName)}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!invoiceClass.isArchived && (
							<UpdateInvoiceClassModal
								invoiceClass={invoiceClass}
							/>
						)
					}
					{
						invoiceClass.isArchived ? (
							<SuccessModal
								id={invoiceClass._id}
								title={'unarchive'}
								icon={FaArrowRotateLeft}
								onAccept={() => unarchiveInvoiceClass(invoiceClass._id)}
								tooltipText={'unarchive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						) : (
							<DangerModal
								id={invoiceClass._id}
								title={'archive'}
								onAccept={() => archiveInvoiceClass(invoiceClass._id)}
								icon={FaArchive}
								tooltipText={'archive'}
								requiredRoles={[RolesEnum.admin, RolesEnum.finances]}
							/>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function InvoiceClassesTable() {
	const { t } = useTranslation()

	const [archiveInvoiceClass] = useArchiveInvoiceClassMutation()
	const [unarchiveInvoiceClass] = useUnarchiveInvoiceClassMutation()

	const { data: invoiceClassesData, isLoading, isError } = useGetInvoiceClassesQuery();

	if (isLoading) return <Loader />;
	if (isError) return <TableError>{t('request_error')}</TableError>;

	const unarchivedInvoiceClassesData = (invoiceClassesData && invoiceClassesData.filter(entry => !entry.isArchived)) || []
	const archivedInvoiceClassesData = (invoiceClassesData && invoiceClassesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveInvoiceClass, unarchiveInvoiceClass]

	return (
		<>
			<Row className='mb-3'>
				<Col xs="auto" className="ml-auto text-right">
					<CreateInvoiceClassModal />
				</Col>
			</Row>
			{
				unarchivedInvoiceClassesData && unarchivedInvoiceClassesData.length > 0 ?
					<UniversalTable
						name={'InvoiceClassesTable_tableName'}
						id='invoiceClasses InvoiceClassesTable_tableName'
						columns={columns}
						data={unarchivedInvoiceClassesData}
						row={
							(invoiceClass, i) =>
								<InvoiceClassRow handlers={handlers} key={invoiceClass._id + 'invoiceClassRow'} invoiceClass={invoiceClass} index={i}/>
						}
						scrollable
						defaultOpen={false}
					/> : <TableError>{t('no_data')}</TableError>
			}
			{
				archivedInvoiceClassesData && archivedInvoiceClassesData.length > 0 ?
					<UniversalTable
						name={'InvoiceClassesTable_tableNameArchived'}
						id='invoiceClasses InvoiceClassesTable_tableNameArchived'
						columns={columns}
						data={archivedInvoiceClassesData}
						row={
							(invoiceClass, i) =>
								<InvoiceClassRow handlers={handlers} key={invoiceClass._id + 'contractorClassRow'} invoiceClass={invoiceClass} index={i}/>
						}
						scrollable
						defaultOpen={false}
					/> :
					null
			}
		</>
	)
}

export default InvoiceClassesTable;
