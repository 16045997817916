import ModalView from './ModalView'
import { IconType } from 'react-icons'
import { RolesEnum } from '../../types/roles'

interface SuccessModalProps {
	id: string;
	title: string
	onAccept: () => void
	icon: IconType
	requiredRoles?: RolesEnum[]
	tooltipText? : string
}

export default function SuccessModal({ id, title, onAccept, icon, requiredRoles, tooltipText }: SuccessModalProps) {
	return (
		<ModalView
			id={`SuccessModal_${title}_${id}`}
			title={title}
			tooltipText={tooltipText}
			buttonIcon={icon}
			buttonText=""
			numberButtonSize={16}
			buttonColor="success"
			tooltipPlacement='top'
			buttonOutline
			className={'rounded-lg'}
			buttonSmall
			onAccept={onAccept}
			acceptButtonText={title}
			acceptButtonColor="success"
			requiredRoles={requiredRoles}
		/>
	)
}