import { Col, Row } from 'react-bootstrap'
import React from 'react'
import CreateAccountModal from './Modals/CreateAccountModal'
import AccountsTable from './Tables/AccountsTable'

function Accounts() {
	return (
		<>
			<Row className="mb-3">
				<Col xs="auto" className="ml-auto text-right">
					<CreateAccountModal />
				</Col>
			</Row>
			<AccountsTable/>
		</>
	)
}

export default Accounts